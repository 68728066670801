import React from 'react';
import _ from 'lodash';
import { buildPropertyString } from '../../../../services/amplitude';

export const trackFragranceCombination = (time = 4000) => {
    const _debouncedFn = (properties) => {
        buildPropertyString({
            category: 'Candles',
            property: 'Fragrance Calculator',
            action: 'Input change',
            properties,
        });
    };
    return _.debounce(_debouncedFn, time);
};

const _debouncedTrackFragranceCombination = trackFragranceCombination();

export const useFragranceMonitoring = ({
    ratio,
    numCandles,
    typeOfCandle,
    highNoteTitle,
    midNoteTitle,
    baseNoteTitle,
    isFirstAnalyticsLoad,
    setIsFirstAnalyticsLoad,
    singleNoteTitle,
    numNotes,
}) => {
    React.useEffect(() => {
        // don't fire if we don't have enough data to even compute
        if (!ratio || !numCandles || !typeOfCandle) {
            setIsFirstAnalyticsLoad(true);
            return;
        }

        if (!isFirstAnalyticsLoad) {
            // This is to not track the first page-load.
            setIsFirstAnalyticsLoad(true);
            return;
        }

        let properties = {
            ratio,
            numCandles,
            typeOfCandle,
            numNotes,
        };

        if (numNotes === 1) {
            properties = {
                ...properties,
                singleNoteTitle,
            };
        } else {
            properties = {
                ...properties,
                highNoteTitle,
                midNoteTitle,
                baseNoteTitle,
            };
        }

        _debouncedTrackFragranceCombination(properties);
    }, [
        ratio,
        isFirstAnalyticsLoad,
        numCandles,
        highNoteTitle,
        midNoteTitle,
        baseNoteTitle,
        typeOfCandle,
        setIsFirstAnalyticsLoad,
    ]);
};

export const trackGenerateLink = (url) => {
    // TODO also remove
    buildPropertyString({
        category: 'Candles',
        property: 'Fragrance Calculator',
        action: 'Generated url',
        properties: { url },
    });
};

export const trackClickedLastSearchLink = (url) => {
    // TODO: cut this out -- nobody is using it
    buildPropertyString({
        category: 'Candles',
        property: 'Fragrance Calculator',
        action: 'Reloaded lastSearch url',
        properties: { url },
    });
};

export const trackTabClick = (tab) => {
    buildPropertyString({
        category: 'Candles',
        property: 'Fragrance Calculator',
        action: 'Clicked tab',
        properties: { tab },
    });
};
