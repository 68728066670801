import React from 'react';
import Axios from 'axios';
import { useQuery } from 'react-query';
import Loading from '../components/Loading';
import PricingTable from './components/PricingTable';
import SearchBar from './components/SearchBar';
import ErrorPage from '../../pages/ErrorPage';
import URLS from '../FragranceCalculator/calculators/FragranceCalculatorV2/urls';
import VendorSelect from './components/VendorSelect';
import { VENDOR_OPTIONS } from './constants';
import { filterByQuery, filterByVendor, flattenVendorsSelect } from './helpers';

const getPricingData = (url) => Axios.get(url);

const FragrancePricing = () => {
    const url = URLS.fragrancePricingUrl;
    const { data, isError, isLoading } = useQuery('pricing-data', () =>
        getPricingData(url)
    );
    const [query, setQuery] = React.useState('');
    const [vendors, setVendors] = React.useState(VENDOR_OPTIONS);

    if (isLoading) return <Loading />;
    if (isError) return <ErrorPage />;

    const flatVendors = flattenVendorsSelect(vendors);
    const fragrances = Object.values(data.data);

    const filteredFragrances = fragrances
        .filter((f) => filterByQuery(f, query))
        .filter((f) => filterByVendor(f, flatVendors));

    return (
        <div>
            <br />
            <SearchBar query={query} setQuery={setQuery} />
            <br />
            <VendorSelect
                id={'test'}
                vendors={vendors}
                setVendors={setVendors}
            />
            <br />
            <PricingTable data={filteredFragrances} />
        </div>
    );
};

export default React.memo(FragrancePricing);
