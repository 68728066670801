import {
    composeValidators,
    isRequired,
    isNumeric,
    combineValidators,
    createValidator,
} from 'revalidate';


export const isValidNumber = createValidator(
    (message) => (value) => {
        try {
            Number(value);
        } catch (err) {
            return message;
        }
    },
    (field) => `${field} needs to be a number`,
);

// ///////////////// STEP ONE VALIDATORS
export const ratioValidator = composeValidators(
    isRequired,
    isValidNumber,
)('Fragrance load percentage');

export const numCandlesValidator = composeValidators(
    isRequired,
    isNumeric,
)('Number of candles');

export const typeOfCandleValidator = composeValidators(
    isRequired,
    isValidNumber,
)('Vessel size');


export const stepOneValidator = combineValidators({
    ratio: ratioValidator,
    numCandles: numCandlesValidator,
    typeOfCandle: typeOfCandleValidator,
});


// ///////////////// STEP TWO VALIDATORS
// there are none!


