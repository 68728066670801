import React from 'react';

import { READABLE_NOTE_MAP } from '../constants';
import { precise, applyNote } from './helpers';

// TODO: add in a totals row
// TODO: add in a description for assumptions:
// 0.2 for high-note/ 0.35 for base/ 0.45 for base

const NoteRow = (props) => {
    const headerNote = props.title
        ? props.title
        : `${READABLE_NOTE_MAP[props.note]} note`;
    return (
        <tr>
            <th scope="row">{headerNote}</th>
            <td>
                {precise(
                    props.waxToFragranceRatio *
                        applyNote({
                            quantity: props.quantity,
                            units: 'g',
                            note: props.note,
                        })
                )}{' '}
                g
            </td>
            <td>
                {precise(
                    props.waxToFragranceRatio *
                        applyNote({
                            quantity: props.quantity,
                            units: 'ml',
                            note: props.note,
                        })
                )}{' '}
                mL
            </td>
            <td>
                {precise(
                    props.waxToFragranceRatio *
                        applyNote({
                            quantity: props.quantity,
                            units: 'drops',
                            note: props.note,
                        })
                )}{' '}
                drops
            </td>
        </tr>
    );
};

export default NoteRow;
