const domain = process.env.GATSBY_CANDLE_SERVICE_DOMAIN;

const URLS = {
    fragranceCalculationUrl: `${domain}/fragrance-calculation`,
    loginFacebook: `${domain}/login/facebook`,
    fragrancePricingUrl: `${domain}/fragrance-pricing/v2`,
    fragrancePricingUrlDetails: `${domain}/fragrance-pricing/v2/details`,
};

export default URLS;
