import React from 'react';
import { Input, InputGroup, Label } from 'reactstrap';
import { trackQueryInput } from './monitoring';
import { useDebouncedCallback } from 'use-debounce';

const SearchBar = (props) => {
    const [inputValue, setInputValue] = React.useState(props.query);

    const debounced = useDebouncedCallback((value) => {
        trackQueryInput(value);
        props.setQuery(value);
    }, 500);
    return (
        <>
            <Label for="price-query">
                <h5>Fragrance name</h5>
                <small>
                    <i>
                        Enter the name of the fragrance you are searching for.
                        It's suggested to keep searches broad as each vendor may
                        name their fragrances slightly differently from each
                        other.
                    </i>
                </small>
            </Label>
            <InputGroup>
                <Input
                    placeholder="eg. Strawberry"
                    type="text"
                    onChange={(e) => {
                        // the visual text values are managed locally
                        // but the parent value used to control when
                        // we actually apply the filter will be controlled
                        // within a debounce callback
                        setInputValue(e.target.value);
                        debounced.callback(e.target.value);
                    }}
                    value={inputValue}
                />
            </InputGroup>
        </>
    );
};

export default SearchBar;
