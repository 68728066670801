import React from 'react'
import { Row, Col } from 'reactstrap'

const Center = (props) => (
    <Row>
        <Col md={3} />
        <Col md={6} style={{ textAlign: 'center' }}>
            <div style={{ display: 'inline-block' }}>{props.children}</div>
        </Col>
        <Col md={3} />
    </Row>
)

export default Center
