import React from 'react';

export const formatFragranceName = (name = '') => {
    return name.replace(/\b\w/g, (l) => l.toUpperCase());
};

export const flattenVendorsSelect = (vendors) => {
    return vendors.map((vendor) => vendor.value);
};

export const filterByVendor = (fragrance, vendors) => {
    if (vendors.includes(fragrance.vendor)) {
        // filter only for fragrances included in vendor
        return true;
    }
    return false;
};

export const filterByQuery = (fragrance, query) => {
    const lowerCaseFragranceName = fragrance.name.toLowerCase();
    const lowerCaseQuery = query.toLowerCase();
    if (lowerCaseFragranceName.includes(lowerCaseQuery)) {
        return true;
    }
    return false;
};
