import React from 'react'
import moment from 'moment'
import { Table, Pagination, PaginationItem, PaginationLink } from 'reactstrap'
import EtsyLoading from './EtsyLoading'
import Center from '../../components/Center'
import { useGraphApi } from '../api'
import * as constants from './constants'
import EtsyTableHeader from './EtsyTableHeader'
import _ from 'lodash'
import { getReadableToDate } from './helpers'
import { useEtsyGraphMonitoring } from './monitoring'

function range(j, k) {
    return Array.apply(null, Array(k - j + 1)).map(function (_, n) {
        return n + j
    })
}

const EtsyTable = ({ toDate, resultsPerPage = 20, results = [] }) => {
    const [currentPage, setCurrentPage] = React.useState(1)
    const [currentColumn, setCurrentColumn] = React.useState('impressions')
    const [columnDirection, setColumnDirection] = React.useState(1)

    useEtsyGraphMonitoring(toDate)

    let dateUrl = ''
    if (toDate) {
        const dateVal = moment(toDate).format('YYYY-MM-DD')
        // both the to_date and the start_date_val are the same since we are getting data on the day OF one day.
        dateUrl = `https://us-central1-etsy-project-273203.cloudfunctions.net/function-1?to_date=${dateVal}&start_date_val=${dateVal}`
    }

    const {
        isLoading: isTableLoading,
        // hasFailed: hasTableFailed,
        data: tableData,
    } = useGraphApi(dateUrl, {})

    const isFiringRequest = isTableLoading && toDate

    if (isTableLoading || isFiringRequest) {
        return <EtsyLoading />
    }

    if (!tableData || !tableData.length) return null

    const readableToDate = getReadableToDate(toDate)

    // TODO: maybe consider how we can have multi-order-bys in the future.
    const orderDirection = columnDirection === 1 ? 'asc' : 'desc'
    const filteredTableData = tableData.filter((datum) => {
        // NOTE: weird cases where there are "no impressons", yet there are conversions
        // if (!datum.avgRank) return false;  // impossible to _not have_ a rank
        // if (!datum.impressions) return false;  // not really useful
        return true
    })
    const sorted = _.orderBy(
        filteredTableData,
        [currentColumn],
        [orderDirection]
    )

    let filteredSorted = sorted.slice()
    let pages = Math.ceil(filteredSorted.length / resultsPerPage)
    // pages = pages % 1 === 0 ? pages : pages + 1;  // we have 7 pages when it should be 6

    if (currentPage === 1) {
        filteredSorted = filteredSorted.slice(0, 20)
    } else if (currentPage > 1 && currentPage < pages) {
        const pageStart = (currentPage - 1) * resultsPerPage
        const pageEnd = currentPage * resultsPerPage
        filteredSorted = filteredSorted.slice(pageStart, pageEnd)
    } else {
        // greater than pages -- default to last page
        const pageStart = (pages - 1) * resultsPerPage
        filteredSorted = filteredSorted.slice(pageStart)
    }

    return (
        <div style={{ paddingTop: '2rem' }}>
            <div
                style={{
                    textAlign: 'center',
                    fontSize: '1.5rem',
                    paddingBottom: '1rem',
                }}
            >
                Data for: {readableToDate}
            </div>
            <Table>
                <thead>
                    <tr>
                        <EtsyTableHeader
                            column={constants.QUERY_COLUMN}
                            header="Query"
                            setCurrentColumn={setCurrentColumn}
                            currentColumn={currentColumn}
                            columnDirection={columnDirection}
                            setColumnDirection={setColumnDirection}
                        />
                        <EtsyTableHeader
                            column={constants.IMPRESSIONS_COLUMN}
                            header="Impressions"
                            setCurrentColumn={setCurrentColumn}
                            currentColumn={currentColumn}
                            columnDirection={columnDirection}
                            setColumnDirection={setColumnDirection}
                        />
                        <EtsyTableHeader
                            column={constants.RANK_COLUMN}
                            header="Rank"
                            setCurrentColumn={setCurrentColumn}
                            currentColumn={currentColumn}
                            columnDirection={columnDirection}
                            setColumnDirection={setColumnDirection}
                        />
                        <EtsyTableHeader
                            column={constants.VISITS_COLUMN}
                            header="Visits"
                            setCurrentColumn={setCurrentColumn}
                            currentColumn={currentColumn}
                            columnDirection={columnDirection}
                            setColumnDirection={setColumnDirection}
                        />
                        <EtsyTableHeader
                            column={constants.CONVERSIONS_COLUMN}
                            header="Conversions"
                            setCurrentColumn={setCurrentColumn}
                            currentColumn={currentColumn}
                            columnDirection={columnDirection}
                            setColumnDirection={setColumnDirection}
                        />
                    </tr>
                </thead>
                <tbody>
                    {filteredSorted.map((datum, i) => {
                        if (!datum.query) return null
                        return (
                            <tr key={`${i}-the-key`}>
                                <td>{datum.query}</td>
                                <td>{datum.impressions || 0}</td>
                                <td>{datum.avgRank || 0}</td>
                                <td>{datum.shopVisits || 0}</td>
                                <td>{datum.conversions || 0}</td>
                            </tr>
                        )
                    })}
                </tbody>
            </Table>
            <Center>
                <Pagination>
                    <PaginationItem>
                        <PaginationLink
                            first
                            onClick={() => setCurrentPage(0)}
                        />
                    </PaginationItem>
                    <PaginationItem>
                        <PaginationLink
                            previous
                            onClick={() => {
                                if (currentPage === 0) return currentPage
                                return setCurrentPage(currentPage - 1)
                            }}
                        />
                    </PaginationItem>
                    {range(1, pages).map((i, _) => {
                        return (
                            <PaginationItem>
                                <PaginationLink
                                    onClick={() => setCurrentPage(i)}
                                >
                                    {i}
                                </PaginationLink>
                            </PaginationItem>
                        )
                    })}
                    <PaginationItem>
                        <PaginationLink
                            next
                            onClick={() => {
                                if (currentPage === pages) return pages
                                return setCurrentPage(currentPage + 1)
                            }}
                        />
                    </PaginationItem>
                    <PaginationItem>
                        <PaginationLink
                            last
                            onClick={() => {
                                return setCurrentPage(pages)
                            }}
                        />
                    </PaginationItem>
                </Pagination>
            </Center>
        </div>
    )
}

EtsyTable.defaultProps = {
    resultsPerPage: 20,
    results: [],
}

export default EtsyTable
