import React from 'react';
import { Input, InputGroup, InputGroupText, Label } from 'reactstrap';
import { Field, FieldProps, useField } from 'formik';
import { ErrorText } from '../../../../FragranceCalculatorV2/inputs/commonTS';

const WaxDensityInput = (fieldInputProps: FieldProps) => {
    return (
        <Input
            {...fieldInputProps.field}
            {...fieldInputProps.meta}
            bsSize="sm"
            type="number"
            placeholder="Enter wax density"
        />
    );
};

const WaxDensity = () => {
    const [, meta] = useField('waxDensity');

    return (
        <>
            <Label for="waxDensity">
                <h5>Wax density:</h5>
                <small>
                    <i>
                        The density of the wax you intend to use. Examples are{' '}
                        <b>0.9 g/mL</b> for Soy wax, <b>0.951</b> g/mL for
                        Beeswax, and <b>0.911</b> g/mL for Paraffin. The soy
                        setting will be used if not filled in.
                    </i>
                </small>
            </Label>
            <InputGroup>
                <Field name="waxDensity" component={WaxDensityInput} />
                <InputGroupText>g/mL</InputGroupText>
            </InputGroup>
            <div>{meta.error ? <ErrorText text={meta.error} /> : null}</div>
            <br />
        </>
    );
};

export default WaxDensity;
