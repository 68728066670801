import { useMutation, useQuery, useQueryClient } from 'react-query';
import Axios from 'axios';
import { useAlert } from 'react-alert';
import camelCaseKeys from 'camelcase-keys';
import { GET_CALCULATIONS_KEY } from '../../constants';

export const useCalculations = (calculationsUrl: string) => {
    const { isLoading, error, data } = useQuery(
        GET_CALCULATIONS_KEY,
        () => Axios.get(calculationsUrl),
        { enabled: !!calculationsUrl }
    );
    const calculations = data?.data?.data ? data?.data?.data : [];

    return { isLoading, error, data: camelCaseKeys(calculations) };
};

export const useDeletion = (deletionUrl: string) => {
    const cb = () => Axios.delete(deletionUrl);
    const queryClient = useQueryClient();

    const alert = useAlert();
    const { mutate, data } = useMutation(cb, {
        onSuccess: async (data) => {
            alert.success('Deleted!');
            await queryClient.refetchQueries(GET_CALCULATIONS_KEY);
        },
        onError: () => {
            alert.error('There was an error. Please try again.');
        },
    });

    return {
        mutate,
        data,
    };
};

export const useView = (viewUrl: string) => {
    const { isLoading, error, data } = useQuery(
        viewUrl, // different than `useCalculations` since it has `:fragrance_id`
        () => Axios.get(viewUrl)
    );

    const calculation = data?.data?.data ? data.data.data : undefined;
    return {
        isLoading,
        data: calculation,
        error,
    };
};
