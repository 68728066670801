import React from 'react';
import {Row, Col, Button} from 'reactstrap';
import {BsChevronLeft, BsChevronRight} from 'react-icons/bs';

import {
    InputDispatchContext,
    InputStateContext,
} from './contexts/InputContext';
import {stepOneValidator} from './validators';
import {MOVE_STEP, UPDATE_ERRORS} from './reducer';

const STEP_BUTTON_TEXT = {
    1: 'Candle details', // TODO: better naming?
    2: 'Fragrance names',
    3: 'Calculations',
};

const getFooterClassName = (step) => {
    if (step === 1) {
        return 'footer-btn-end';
    } else if (step < 3) {
        return 'footer-btn-mid';
    } else {
        return 'footer-btn-start';
    }
};

const FooterButtons = () => {
    const state = React.useContext(InputStateContext);
    const dispatch = React.useContext(InputDispatchContext);

    const footerClass = getFooterClassName(state.step);

    return (
        <div className={footerClass}>
            <>
                {state.step > 1 && (
                    <div>
                        <Button
                            size="lg"
                            color="primary"
                            onClick={() => {
                                dispatch({
                                    type: MOVE_STEP,
                                    payload: {
                                        value: state.step - 1,
                                    },
                                });
                            }}
                        >
                            <BsChevronLeft />
                        </Button>
                    </div>
                )}</>
            <div />
            <>
                {state.step < 3 && (
                    <div>
                        <Button
                            size="lg"
                            color="primary"
                            onClick={() => {
                                const errors = stepOneValidator(state);
                                if (Object.keys(errors).length) {
                                    dispatch({
                                        type: UPDATE_ERRORS,
                                        payload: {
                                            errors,
                                        },
                                    });
                                } else {
                                    dispatch({
                                        type: MOVE_STEP,
                                        payload: {
                                            value: state.step + 1,
                                        },
                                    });
                                }
                            }}
                        >
                            <BsChevronRight />
                        </Button>
                    </div>
                )}</>
        </div>
    );
};

export default FooterButtons;
