import React from 'react'
import ReactHighcharts from 'react-highcharts'

import buildConfig from './config'
import { useGraphApi } from '../api'
import { prepareGraphData } from './helpers'
import EtsyLoading from './EtsyLoading'

const readMongoUrl =
    'https://us-central1-etsy-project-273203.cloudfunctions.net/read_mongo'

const EtsyGraph = (props) => {
    const {
        isLoading: isMongoLoading,
        // hasFailed: hasMongoFailed,
        data: mongoData,
    } = useGraphApi(readMongoUrl, {})

    if (isMongoLoading) return <EtsyLoading />

    const chartData = prepareGraphData(mongoData)

    const pointClick = (event) => {
        // setScore({
        // 	toDate: event.point.x,
        // 	value: event.point.y,  // we may not need this...
        // })
        props.setToDate(event.point.x)
    }

    const config = buildConfig(chartData, pointClick)
    const graph = <ReactHighcharts config={config} />
    return graph
}

export default React.memo(EtsyGraph)
