import React from 'react'
import { applyNote, precise } from '../table/helpers'
import FragranceCard from "./FragranceCard";

const SingleFragrance = (props) => {
    const amount = precise(
        props.waxToFragranceRatio *
            applyNote({
                quantity: props.quantity,
                units: props.unit,
                note: 'SING',
            })
    )

    return (
        <>
            <FragranceCard
                noteAmount={amount}
                title={props.singleNoteTitle}
                defaultTitle="Fragrance note"
                unit={props.unit}
            />
        </>
    )
}

export default SingleFragrance
