import React from 'react';
import { inputReducer } from '../reducer';
import { getStateFromQueryParams } from '../helpers';

export const InputStateContext = React.createContext({});
export const InputDispatchContext = React.createContext(() => {});

const defaults = getStateFromQueryParams();

const InputProvider = ({ children }) => {
    const [inputState, inputDispatch] = React.useReducer(inputReducer, {
        ...defaults,
        errors: {},
        step: 1, // start off at initial step
    });
    return (
        <InputStateContext.Provider value={inputState}>
            <InputDispatchContext.Provider value={inputDispatch}>
                {children}
            </InputDispatchContext.Provider>
        </InputStateContext.Provider>
    );
};

export default InputProvider;
