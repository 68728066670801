import React from 'react';
import { Table } from 'reactstrap';

const PRODUCT_TABLE = {
    paradise: {
        name: 'Paradise',
        source: 'https://www.etsy.com/listing/802208473/mens-candles-simple-minimal-4oz-amber',
        cost: 4.49,
    },
    simple: {
        name: 'Simple',
        source: 'https://www.etsy.com/listing/787370234/candles-simple-minimal-4oz-amber-candle',
        cost: 3.64,
    },
    sweet: {
        name: 'Sweet',
        source: 'https://www.etsy.com/listing/786871254/mens-candles-simple-minimal-4oz-amber',
        cost: 2.08,
    },
    warmth: {
        name: 'Warmth',
        source: 'https://www.etsy.com/listing/781426047/mens-candles-simple-minimal-plain-amber',
        cost: 2.81,
    }
}

const data = [
    {
        quantity: 2,
        type: 'paradise',
        price: 25,
        user: 'toddshouston78',
    },
    {
        quantity: 2,
        type: 'simple',
        price: 20,
        user: 'toddshouston78',
    },
    {
        quantity: 2,
        type: 'sweet',
        price: 20,
        user: 'toddshouston78',
    },
    {
        quantity: 1,
        type: 'paradise',
        price: 25,
        user: 'sarahszor',
    },
    {
        quantity: 2,
        type: 'warmth',
        price: 20,
        user: null, // guest
    }

]


const DonationTable = (props) => {
    return (
        <Table>
            <thead>
            <tr>
                <th>
                    Product
                </th>
                <th>
                    Quantity
                </th>
                <th>
                    Candle cost
                </th>
                <th>
                    Sale price
                </th>
                <th>
                    Profit
                </th>
                <th>
                    15% profit
                </th>
                <th>
                    User
                </th>
            </tr>
            </thead>
            <tbody>
            {
                data.map((el) => {
                    const { name, source, cost } = PRODUCT_TABLE[el.type];

                    const profit = (el.price - cost) * el.quantity;
                    const fifteenPercent = profit * 0.15

                    return (
                      <tr>
                          <td>
                              <a className="post-list-header" href={ source } target="_blank" rel="noopener">{ name }</a>
                          </td>
                          <td>
                              { el.quantity }
                          </td>
                          <td>
                             ${ cost }
                          </td>
                          <td>
                              ${ el.price }
                          </td>
                          <td>
                              ${ profit.toFixed(2) }
                          </td>
                          <td>
                              ${ fifteenPercent.toFixed(2)}
                          </td>
                          <td>
                            {
                                el.user ? (

                              <a className="post-list-header" href={`https://www.etsy.com/people/${el.user}`} target="_blank" rel="noopener">{ el.user }</a>
                          ) : <span className="post-list-header">guest</span>
                            }
                          </td>
                      </tr>
                    )
                })
            }
            </tbody>
        </Table>
    )
}

export default DonationTable;
