import LogRocket from 'logrocket';
import { useAuth0 } from '@auth0/auth0-react';

export const useAuth0Hook = () => {
    const values = useAuth0();
    if (values.isAuthenticated) {
        if (
            values.user &&
            values.user.sub &&
            values.user.email &&
            values.user.name
        ) {
            // identify logrocket session
            LogRocket.identify(values.user.sub, {
                email: values.user.email,
                name: values.user.name,
            });
        }
    }

    return {
        userId:
            values.isAuthenticated && values.user && values.user.sub
                ? values.user.sub
                : undefined,
        auth0Values: values,
    };
};
