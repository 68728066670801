import React from 'react';
import { Input, InputGroup, InputGroupText, Label } from 'reactstrap';
import { Field, FieldProps, useField } from 'formik';
import { ErrorText } from '../../../../FragranceCalculatorV2/inputs/commonTS';

const FragranceLoadPercentageInput = (fieldInputProps: FieldProps) => {
    return (
        <Input
            {...fieldInputProps.field}
            {...fieldInputProps.meta}
            type="number"
            bsSize="sm"
            placeholder="Enter fragrance load percentage"
        />
    );
};

const FragranceLoadPercentage = () => {
    const [_field, meta] = useField('fragranceLoadPercentage');

    return (
        <>
            <Label for="fragranceLoadPercentage">
                <h5>Fragrance load percentage:</h5>
                <small>
                    <i>
                        Percentage of fragrance oil used per candle. If you
                        wanted to have a fragrance load percentage of <b>10%</b>{' '}
                        you'd enter 10.{' '}
                        <a
                            href="https://support.candlescience.com/hc/en-us/articles/201352174-How-much-fragrance-oil-should-I-add-to-melted-wax-"
                            target="_blank"
                        >
                            Candlescience
                        </a>{' '}
                        has a good article on determining this ratio.
                    </i>
                </small>
            </Label>
            <InputGroup>
                <Field
                    name="fragranceLoadPercentage"
                    component={FragranceLoadPercentageInput}
                />
                <InputGroupText>%</InputGroupText>
            </InputGroup>
            <div>{meta.error ? <ErrorText text={meta.error} /> : null}</div>
            <br />
        </>
    );
};

export default FragranceLoadPercentage;
