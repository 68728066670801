import React from 'react'

const TableBody = ({ data }) => {
    return (
        <tbody>
            {data.map((el) => {
                return (
                    <tr>
                        <td>
                            <img src={el.image} />
                        </td>
                        <td>{el.name}</td>
                        <td>
                            <p>
                                {`$${el.min_price}`} to {`$${el.max_price}`}
                            </p>
                        </td>
                        <td>{el.rating}</td>
                        <td>{el.soy_performance}</td>
                        <td>{el.search_volume}</td>
                    </tr>
                )
            })}
        </tbody>
    )
}

export default TableBody
