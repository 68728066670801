import React from 'react';
import { Label, Input, InputGroup, InputGroupText } from 'reactstrap';
import { ErrorText } from './common';

const FragranceToWaxInput = (props) => {
    const [touched, setTouched] = React.useState(false);
    return (
        <>
            <Label for="frag-to-wax-ratio">
                <h5>Fragrance load percentage:</h5>
                <small>
                    <i>
                        Percentage of fragrance oil used per candle. If you
                        wanted to have a fragrance load percentage of <b>10%</b>{' '}
                        you'd enter 10.{' '}
                        <a
                            href="https://support.candlescience.com/hc/en-us/articles/201352174-How-much-fragrance-oil-should-I-add-to-melted-wax-"
                            target="_blank"
                        >
                            Candlescience
                        </a>{' '}
                        has a good article on determining this ratio.
                    </i>
                </small>
            </Label>
            <InputGroup>
                <Input
                    type="number"
                    name="select"
                    id="frag-to-wax-ratio"
                    bsSize="small"
                    value={props.value}
                    onChange={(e) => {
                        if (!touched) setTouched(true);
                        props.setFn(e.target.value);
                    }}
                    placeholder="Enter fragrance load percentage"
                />
                <InputGroupText>%</InputGroupText>
            </InputGroup>
            <div>
                {touched && !props.value ? (
                    <ErrorText text="Missing fragrance to wax ratio" />
                ) : null}
            </div>
            <br />
        </>
    );
};

export default FragranceToWaxInput;
