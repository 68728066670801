import React from 'react';
import { Input, InputGroup, Label } from 'reactstrap';
import { Field, FieldProps, useField } from 'formik';
import { ErrorText } from '../../../../FragranceCalculatorV2/inputs/commonTS';

const CandleNameInput = (fieldInputProps: FieldProps) => {
    return (
        <Input
            {...fieldInputProps.field}
            {...fieldInputProps.meta}
            bsSize="sm"
            placeholder="Enter candle name"
        />
    );
};

const CandleName = () => {
    const [, meta] = useField('candleName');

    return (
        <>
            <Label for="candleName">
                <h5>Candle name:</h5>
                <small>
                    <i>
                        The name of your candle (i.e. Lavender & Vanilla, or
                        Forest).
                    </i>
                </small>
            </Label>
            <InputGroup>
                <Field name="candleName" component={CandleNameInput} />
            </InputGroup>
            <div>{meta.error ? <ErrorText text={meta.error} /> : null}</div>
            <br />
        </>
    );
};

export default CandleName;
