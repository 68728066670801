import React from 'react'
import { FormGroup, Form } from 'reactstrap'

import FragranceToWaxInput from './FragranceToWaxInput'
import NumCandlesInput from './NumCandlesInput'
import TypeOfCandleInput from './TypeOfCandleInput'
import NoteTypeInput from './NoteTypeInput'
import NumNotesInput from './NumNotesInput'

const Inputs = (props) => {
    return (
        <Form>
            <FormGroup>
                <NumNotesInput
                    setFn={props.setNumNotes}
                    value={props.numNotes}
                />
                <br />
                <FragranceToWaxInput
                    setFn={props.setRatio}
                    value={props.ratio}
                />
                <br />
                <NumCandlesInput
                    setFn={props.setNumCandles}
                    value={props.numCandles}
                />
                <br />
                <TypeOfCandleInput
                    setFn={props.setTypeOfCandle}
                    value={props.typeOfCandle}
                />
                <br />
                <NoteTypeInput
                    numNotes={props.numNotes}
                    highNoteTitle={props.highNoteTitle}
                    setHighNoteTitle={props.setHighNoteTitle}
                    midNoteTitle={props.midNoteTitle}
                    setMidNoteTitle={props.setMidNoteTitle}
                    baseNoteTitle={props.baseNoteTitle}
                    setBaseNoteTitle={props.setBaseNoteTitle}
                    singleNoteTitle={props.singleNoteTitle}
                    setSingleNoteTitle={props.setSingleNoteTitle}
                />
            </FormGroup>
        </Form>
    )
}

export default Inputs
