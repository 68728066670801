import React from 'react';
import { logAmplitude } from '../services/amplitude';

// type PropTypes = {
//     event: string,
//     text: string,
//     url: string,
// };

export const TrackClick = ({ event, text, url }) => {
    return (
        <a href={url} target="_blank" onClick={() => logAmplitude(event)}>
            {text}
        </a>
    );
};
