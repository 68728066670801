import React from 'react';
import { Table } from 'reactstrap';
import { pluralizeCandle } from '../../../../FragranceCalculatorV2/inputs/commonTS';
import { DENSITY_SOYWAX } from '../../../constants';

type PropTypes = {
    candleName: string;
    numCandles?: number;
    fragranceLoadPercentage: number;
    vesselSize: number;
    // fragranceName?: string;
    waxDensity?: number;
};

const ResultsTable = (props: PropTypes) => (
    // TODO: maybe include an explanation of the numbers at the bottom of this table?
    <Table borderless responsive>
        <tbody>
            {props.candleName ? (
                <tr>
                    <td>
                        <b>Candle name</b>
                    </td>
                    <td>{props.candleName}</td>
                </tr>
            ) : null}
            <tr>
                <td>
                    <b>Number of candles</b>
                </td>
                <td>
                    {props.numCandles} {pluralizeCandle(props.numCandles)}
                </td>
            </tr>
            <tr>
                <td>
                    <b>Vessel size</b>
                </td>
                <td>{props.vesselSize} oz</td>
            </tr>
            <tr>
                <td>
                    <b>Fragrance load %</b>
                </td>
                <td>{props.fragranceLoadPercentage} %</td>
            </tr>
            <tr>
                <td>
                    <b>Wax density</b>
                </td>
                <td>{props.waxDensity || DENSITY_SOYWAX} g/mL</td>
            </tr>
        </tbody>
    </Table>
);

export default ResultsTable;
