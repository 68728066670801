import React from 'react'
import NumNotesInput from '../inputs/NumNotesInput'
import NoteTypeInput from '../inputs/NoteTypeInput'
import CandleNameInput from '../inputs/CandleNameInput'
import Assumptions from "../Assumptions";
import { InputStateContext } from "../contexts/InputContext";

const FragranceInfoTab = () => {
    const { numNotes } = React.useContext(InputStateContext)
    return (
        <>
            <div style={{ paddingTop: '1em' }}>
                <small>
                    The information below is optional. Anything you fill out
                    below will be used for saving your candle calculations if
                    you decide to save them. An example would be if I had a
                    candle line named "Forest", and it consisted of 3
                    fragrances, I'd fill out everything below, save my candle
                    calculations, then for any future productions, I'll be able
                    to directly access my saved "Forest" calculation.
                </small>
            </div>
            <br />
            <br />
            <CandleNameInput />
            <br />
            <br />
            <NumNotesInput />
            <br />
            <br />
            <NoteTypeInput />
        </>
    )
}

export default FragranceInfoTab
