import React from 'react';
import { Label, Input, InputGroup, InputGroupText } from 'reactstrap';
import { ErrorText } from './common';

const TypeOfCandleInput = (props) => {
    const [touched, setTouched] = React.useState(false);
    return (
        <>
            <Label for="type-of-cnadle-input">
                <h5>Vessel size:</h5>
                <small>
                    <i>
                        Unit of candle in oz. You'd enter <b>8</b> if you were
                        making 8oz candles.
                    </i>
                </small>
            </Label>
            <InputGroup>
                <Input
                    type="number"
                    name="type-of-candle-input"
                    id="type-of-candle-input"
                    bsSize="small"
                    onChange={(e) => {
                        if (!touched) setTouched(true);
                        props.setFn(e.target.value);
                    }}
                    value={props.value}
                    placeholder="Enter vessel size"
                />
                <InputGroupText>oz</InputGroupText>
            </InputGroup>
            {touched && !props.value ? (
                <ErrorText text="Missing vessel size" />
            ) : null}
            <br />
        </>
    );
};

export default TypeOfCandleInput;
