import React from 'react';
import {
    Dropdown,
    DropdownMenu,
    DropdownToggle,
    DropdownItem,
} from 'reactstrap';
import { useDeletion } from './hooks';
import ViewingModal from './ViewingModal';
import { URLS } from '../../constants';

type PropTypes = {
    userId: string;
    fragranceId: string;
};

const CalculationsDropdown = (props: PropTypes) => {
    const baseUrl = URLS.fragranceCalculationUrl;
    const calculationUrl = `${baseUrl}/${props.userId}/${props.fragranceId}`;

    const [isDropDownOpen, setIsDropDownOpen] = React.useState(false);
    const { mutate: deleteCalc } = useDeletion(calculationUrl);
    // When in "Load", this'll take advantage of the URL
    //    - or actually, we can use react-query to perform this refresh
    //
    // The Edit was commented out for scope reasons. Let's just force the user
    // to edit one.
    //
    // The interaction post-creation is kinda weird. we haven't defined that part yet

    /*
    When trying to load a fragrance, how it'd work would be:
        1. fire an endpoint with a &fragrance_id=XXXXX
        2. we get the data back, then load that into our state through our reducer action
        3.
    */
    const [isViewing, setIsViewing] = React.useState(false);

    return (
        <>
            {isViewing && calculationUrl ? (
                <ViewingModal
                    setIsViewing={setIsViewing}
                    isOpen={isViewing}
                    calculationUrl={calculationUrl}
                />
            ) : null}
            <Dropdown
                size="sm"
                isOpen={isDropDownOpen}
                toggle={() => setIsDropDownOpen(!isDropDownOpen)}
            >
                <DropdownToggle caret>Actions</DropdownToggle>
                <DropdownMenu>
                    {calculationUrl ? (
                        <DropdownItem onClick={() => setIsViewing(true)}>
                            View
                        </DropdownItem>
                    ) : null}
                    {calculationUrl ? (
                        <DropdownItem
                            onClick={async () => {
                                const confirmed = window.confirm(
                                    'Are you sure you want to delete?'
                                );
                                if (confirmed) {
                                    await deleteCalc();
                                }
                            }}
                        >
                            Delete
                        </DropdownItem>
                    ) : null}
                    {/* <DropdownItem>*/}
                    {/*    Edit*/}
                    {/* </DropdownItem>*/}
                </DropdownMenu>
            </Dropdown>
        </>
    );
};

export default CalculationsDropdown;
