import { buildPropertyString } from "../../../services/amplitude";
import moment from "moment";


export const trackQueryInput = (query) => {
    buildPropertyString({
        category: 'Candles',
        property: 'Price comparison tool',
        action: 'Price query input',
        properties: {
            query,
            toDate: moment().format('MM/DD/YYYY'),
        },
    })
}


export const trackPriceLinkClick = (name, url, vendor) => {
    buildPropertyString({
        category: 'Candles',
        property: 'Price comparison tool',
        action: 'Vendor link click',
        properties: {
            vendor,
            url,
            name,
        }
    })
}
