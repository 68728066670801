import React from 'react';
import { Card, CardBody } from 'reactstrap';
import ResultsTable from '../ResultsTable';

type PropTypes = {
    candleName: string;
    numCandles?: number;
    fragranceLoadPercentage: number;
    vesselSize: number;
    // fragranceName?: string;
    waxDensity?: number;
};

const ResultsTableCard = (props: PropTypes) => {
    return (
        <Card style={{ backgroundColor: '#FBF8F1' }}>
            <CardBody>
                <ResultsTable {...props} />
            </CardBody>
        </Card>
    );
};

export default ResultsTableCard;
