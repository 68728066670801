import Moment from 'moment'

export const sortDataByDate = (transformedData) => {
    return transformedData.sort((curr, next) => {
        return new Moment(curr.date) - new Moment(next.date)
    })
}

export const transformForChart = (data) => {
    return data.map((el) => [Moment(el.date).valueOf(), el.value])
}

export const performTransform = (data) => {
    /*
		takes in data that is of object form:
		{
			'2020-02-20': 45,
		}

		into:

		[
			{
				date: '2020-02-20',
				value: 45,
			}
		]
	*/

    return Object.keys(data).map((dateKey) => {
        return {
            date: dateKey,
            value: data[dateKey],
        }
    })
}

export const prepareGraphData = (data) => {
    const transformedData = performTransform(data)
    const sortedData = sortDataByDate(transformedData)
    const chartData = transformForChart(sortedData)

    return chartData
}

export const getReadableToDate = (toDate) => {
    if (!toDate) return ''
    return Moment(toDate).format('MMMM Do YYYY')
}
