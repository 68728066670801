import React from 'react';
import { Input, InputGroup, Label } from 'reactstrap';
import {
    InputDispatchContext,
    InputStateContext,
} from '../../contexts/InputContext';
import { UPDATE_ACTION } from '../../reducer';

const SingleNoteNameInput = (props) => {
    const dispatch = React.useContext(InputDispatchContext);
    const { singleNoteTitle } = React.useContext(InputStateContext);
    return (
        <>
            <Label for="single-candle-note-input">
                <h5>Fragrance name:</h5>
                <small>
                    <i>
                        The scent you intend your candle to have (i.e.
                        Strawberry).
                    </i>
                </small>
            </Label>
            <InputGroup>
                <Input
                    type="text"
                    name="single-candle-note-input"
                    id="single-candle-note-input"
                    bsSize="small"
                    value={singleNoteTitle}
                    placeholder="Strawberry"
                    onChange={(e) => {
                        dispatch({
                            type: UPDATE_ACTION,
                            payload: {
                                key: 'singleNoteTitle',
                                value: e.target.value,
                            },
                        });
                    }}
                />
            </InputGroup>
        </>
    );
};

export default SingleNoteNameInput;
