import React from 'react';
import { CardTitle, CardText, Card, Button, Row, Col } from 'reactstrap';

import SingleFragrance from '../FragranceCards/SingleFragranceCard';
import MultiFragranceCard from '../FragranceCards/MultiFragranceCard';
import InputTable from '../table/FragranceCalculationTable/InputTable';
import UnitSelector from '../inputs/UnitSelector';
import { UNIT_GRAM } from '../constants';
import SelectedText from '../SelectedText';
import { useCreation } from './hooks';
import { applyConversion, precise } from '../table/helpers';
import URLS from '../urls';
import LoginButton from '../../../../../components/LoginButton';
import Jumbotron from '../../../../../components/Jumbotron';

const GRAMS_TO_OUNCE = 0.0352; // 1 gram = 0.035 oz

const useShouldMutate = (props) => {
    const [shouldMutate, setShouldMutate] = React.useState(true);
    React.useEffect(() => {
        setShouldMutate(true);
    }, [
        props.candleName,
        props.ratio,
        props.numCandles,
        props.typeOfCandle,
        props.highNoteTitle,
        props.midNoteTitle,
        props.baseNoteTitle,
        props.singleNoteTitle,
        props.numNotes,
    ]);

    return {
        shouldMutate,
        setShouldMutate,
    };
};

const FragranceCalculations = (props) => {
    // multiply out the total quantity in oz, then find it in grams
    const quantity = (props.numCandles * props.typeOfCandle) / GRAMS_TO_OUNCE;
    const waxToFragranceRatio = Number(props.ratio) / 100;

    /*
    Ideas:
    - Tie back to fragrance pricing:
        if there is a match for the fragrance, display it here
    - A button to convert the #'s to ml/g/oz
    - Additional notes section (?)
    - An intro text above (?)
    - Actual save button to persist the fragrance

    TODO refactor:
    -- a hook that'll return the unit to apply, as well as the quantity

    CardConfig = {
       Name,
       Quantity,
       Additional Instructions (?)
          - Issue with this is that it makes it difficult for us to provide specific
          information
    }
    return: [CardConfig, CardConfig, CardConfig]

    This way, we can easily scale to 4, 5+ cards. The only thing we'd be tweaking is the
    data as this component is able to consume the data to display the appropriate number
    of cards.

    This refactor would probably include changing how we track fragranceNames. There wouldn't
    be an explicit distinction between single/multi, rather there'd just be a list of
    fragrance percentages that'll receive X% of fragrance of the total fragrance quantity.
        - fragrance quantity = total volume - wax
    */
    const [unit, setUnit] = React.useState(UNIT_GRAM);

    const { shouldMutate, setShouldMutate } = useShouldMutate(props);

    const url = URLS.fragranceCalculationUrl;
    const data = {
        // This data is specific for the backend
        auth0_user_id: props.user_id,
        name: props.candleName,
        fragrance_load_percentage: props.ratio,
        number_of_candles: props.numCandles,
        vessel_size: props.typeOfCandle,

        high_note_name: props.highNoteTitle,
        mid_note_name: props.midNoteTitle,
        base_note_name: props.baseNoteTitle,
        single_note_name: props.singleNoteTitle,
        num_notes: props.numNotes,

        // calculation_id, // TODO: add to the front-end
        // OR actually -- this'll always be empty.
    };
    const { mutate } = useCreation(url, data);
    const cannotMutate = !shouldMutate || !props.user_id;

    // total of fragrance oil + wax should be the Xoz of the candle.
    // i.e. for a 4oz candle, it should be: (0.9) * 4 of wax, and (0.1) * 4 of oil
    const waxQuantity = quantity * (1 - waxToFragranceRatio);
    const convertedWax = applyConversion({
        quantity: waxQuantity,
        units: unit,
    });

    return (
        <>
            <div style={{ paddingTop: '1em' }}>
                <SelectedText
                    numCandles={props.numCandles}
                    typeOfCandle={props.typeOfCandle}
                    ratio={props.ratio}
                />
                <div>
                    <InputTable
                        waxToFragranceRatio={waxToFragranceRatio}
                        numCandles={props.numCandles}
                        typeOfCandle={props.typeOfCandle}
                        numNotes={props.numNotes}
                        candleName={props.candleName}
                    />
                </div>
                <div>
                    <Jumbotron>
                        <Row>
                            <Col md="2" />
                            <Col md="8">
                                <UnitSelector setUnit={setUnit} unit={unit} />
                            </Col>
                            <Col md="2" />
                        </Row>
                    </Jumbotron>
                </div>
                <div>
                    <Card body>
                        {
                            // The unit for wax will always be grams
                        }
                        <CardTitle tag="h3">
                            Wax: {precise(convertedWax)} {unit}
                        </CardTitle>
                        <CardText>
                            <p>
                                Prepare{' '}
                                <b>
                                    {precise(convertedWax)} {unit}
                                </b>{' '}
                                of wax to blend with your fragrance oils.
                            </p>
                            <small>
                                <i>
                                    Different types of waxes (soy, paraffin,
                                    beeswax, etc.) will yield different results.
                                    Be sure to do your research to determine
                                    which wax works best for you.
                                </i>
                            </small>
                        </CardText>
                    </Card>
                    <br />
                    {props.numNotes === 3 ? (
                        <MultiFragranceCard
                            quantity={quantity}
                            waxToFragranceRatio={waxToFragranceRatio}
                            highNoteTitle={props.highNoteTitle}
                            midNoteTitle={props.midNoteTitle}
                            baseNoteTitle={props.baseNoteTitle}
                            unit={unit}
                        />
                    ) : (
                        <SingleFragrance
                            quantity={quantity}
                            waxQuantity={waxQuantity}
                            waxToFragranceRatio={waxToFragranceRatio}
                            singleNoteTitle={props.singleNoteTitle}
                            unit={unit}
                        />
                    )}
                </div>
                <br />
                <br />
                {props.showSave ? (
                    <div
                        style={{ backgroundColor: '#d9e4dd', padding: '3rem' }}
                    >
                        <Row>
                            <Col md="10">
                                <h5 style={{ marginBottom: '1.5rem' }}>
                                    Want to not have to re-enter this
                                    information in the future?
                                </h5>
                                <div>
                                    Consider saving this calculation. By saving
                                    this calculation, you'll be able to
                                    consolidate all your candles fragrance
                                    calculations into one place, and no more
                                    will you have to re-enter your candles'
                                    information. Save your calculations and
                                    we'll pre-load them everytime you login! 🎉
                                    🎉
                                </div>
                                <br />
                                {!shouldMutate && (
                                    <div>
                                        Connect your facebook, and then you'll
                                        be able to save your calculations!
                                    </div>
                                )}
                                {
                                    // TODO:
                                    // 1. non-logged in user comes here and wants to save info / sign-up
                                    // 2. maybe put this info in the front-end... and then pull from it
                                    // 3. after the redirect or something...
                                }
                                {!props.isAuthenticated && <LoginButton />}
                                <br />
                                <Button
                                    color="#eee6f57"
                                    style={{
                                        backgroundColor: '#ee6f57',
                                        marginTop: '2rem',
                                        paddingLeft: '2rem',
                                        paddingRight: '2rem',
                                    }}
                                    size={'sm'}
                                    disabled={cannotMutate}
                                    onClick={async () => {
                                        await mutate();

                                        // after submission, set this to `false` so
                                        // we try and prevent multiple submissions
                                        setShouldMutate(false);
                                    }}
                                >
                                    Save calculation
                                </Button>
                            </Col>
                        </Row>
                    </div>
                ) : null}
            </div>
        </>
    );
};

export default FragranceCalculations;
