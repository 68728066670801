import React from 'react'

const CenteredPTag = ({ text }) => {
    return (
        <p
            style={{
                textAlign: 'center',
            }}
        >
            {text}
        </p>
    )
}

export default CenteredPTag
