import * as constants from './constants';
import {NUM_NOTE_SELECTIONS} from './constants';

export const generateUrl = ({
    ratio,
    numCandles,
    typeOfCandle,
    highNoteTitle,
    midNoteTitle,
    baseNoteTitle,
    numNotes,
    singleNoteTitle,
}) => {
    let baseUrl = `${window.location.href}?`;
    if (ratio) {
        baseUrl = `${baseUrl}${constants.FRAGRANCE_TO_WAX_RATIO_QUERY_PARAM}=${ratio}&`;
    }
    if (numCandles) {
        baseUrl = `${baseUrl}${constants.NUM_CANDLE_QUERY_PARAM}=${numCandles}&`;
    }
    if (typeOfCandle) {
        baseUrl = `${baseUrl}${constants.TYPE_OF_CANDLE_QUERY_PARAM}=${typeOfCandle}&`;
    }
    if (highNoteTitle) {
        baseUrl = `${baseUrl}${constants.HIGH_NOTE_QUERY_PARAM}=${highNoteTitle}&`;
    }
    if (midNoteTitle) {
        baseUrl = `${baseUrl}${constants.MID_NOTE_QUERY_PARAM}=${midNoteTitle}&`;
    }
    if (baseNoteTitle) {
        baseUrl = `${baseUrl}${constants.BASE_NOTE_QUERY_PARAM}=${baseNoteTitle}&`;
    }
    if (numNotes) {
        baseUrl = `${baseUrl}${constants.NUM_NOTE_QUERY_PARAM}=${numNotes}&`;
    }
    if (singleNoteTitle) {
        baseUrl = `${baseUrl}${constants.SINGLE_NOTE_QUERY_PARAM}=${singleNoteTitle}&`;
    }
    return baseUrl;
};

export const getStateFromQueryParams = () => {
    try {
        // breaks when bundling in CI
        if (!window || !window.location || !window.location.search) return {};
    } catch (err) {
        return {};
    }

    const queryParams = new URLSearchParams(window.location.search);

    return {
        fragranceToWaxRatio: extractValue({
            queryParams,
            key: constants.FRAGRANCE_TO_WAX_RATIO_QUERY_PARAM,
            defaultValue: constants.FRAGRANCE_TO_WAX_RATIO_CHOICES[0],
            validators: [isNumber, isPositive],
        }),
        numCandles: extractValue({
            queryParams,
            key: constants.NUM_CANDLE_QUERY_PARAM,
            defaultValue: constants.NUM_CANDLE_CHOICES[0],
            validators: [isNumber, isPositive],
        }),
        typeOfCandle: extractValue({
            queryParams,
            key: constants.TYPE_OF_CANDLE_QUERY_PARAM,
            defaultValue: constants.TYPE_OF_CANDLE_CHOICES[0],
            validators: [isNumber, isPositive],
        }),
        highNote: extractValue({
            queryParams,
            key: constants.HIGH_NOTE_QUERY_PARAM,
            defaultValue: '',
            validators: [isString],
        }),
        midNote: extractValue({
            queryParams,
            key: constants.MID_NOTE_QUERY_PARAM,
            defaultValue: '',
            validators: [isString],
        }),
        baseNote: extractValue({
            queryParams,
            key: constants.BASE_NOTE_QUERY_PARAM,
            defaultValue: '',
            validators: [isString],
        }),
        numNotes: extractValue({
            queryParams,
            key: constants.NUM_NOTE_QUERY_PARAM,
            defaultValue: '1',
            validators: [isInSelection],
        }),
        singleNote: extractValue({
            queryParams,
            key: constants.SINGLE_NOTE_QUERY_PARAM,
            defaultValue: '',
            validators: [isString],
        }),
    };
};

export const applyValidators = (value, validators) => {
    if (validators) {
        return validators.reduce((accu, validator) => {
            if (accu && validator(accu)) return accu;
            return null;
        }, value);
    }
};

export const extractValue = ({
    queryParams,
    key,
    validators,
    defaultValue,
}) => {
    const val = queryParams.get(key);
    if (val) {
        const validatedData = applyValidators(val, validators);
        if (validatedData) return validatedData;
    }
    return defaultValue;
};

export const isNumber = (val) => {
    try {
        return parseInt(val);
    } catch (err) {
        return undefined;
    }
};

export const isString = (val) => {
    return typeof val === 'string' && val;
};

export const isInSelection = (val) => {
    return NUM_NOTE_SELECTIONS.includes(val);
};

export const isPositive = (val) => {
    try {
        const parsedVal = parseInt(val);
        if (parsedVal >= 0) return val;
    } catch (err) {
        return undefined;
    }
};

export const canGenerateUrl = (ratio, numCandles, typeOfCandle) =>
    ratio && numCandles && typeOfCandle;

export const convertToPercent = (value) => `${value * 100}%`;
