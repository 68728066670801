import React from 'react'
import { Table } from 'reactstrap'
import { pluralizeCandle } from '../../inputs/common'

const InputTable = (props) => {
    return (
        <Table size="md" borderless={true} bordered>
            <tbody>
                {props.candleName ? (
                    <tr>
                        <td><b>Candle name</b></td>
                        <td>{props.candleName}</td>
                    </tr>
                ) : null}
                <tr>
                    <td><b>Number of candles</b></td>
                    <td>
                        {props.numCandles} {pluralizeCandle(props.numCandles)}
                    </td>
                </tr>
                <tr>
                    <td><b>Vessel size</b></td>
                    <td>{props.typeOfCandle} oz</td>
                </tr>
                <tr>
                    <td><b>Fragrance load</b></td>
                    <td>{props.waxToFragranceRatio * 100} %</td>
                </tr>
                {props.numNotes ? (
                    <tr>
                        <td><b>Number of fragrances</b></td>
                        <td>{props.numNotes}</td>
                    </tr>
                ) : null}
            </tbody>
        </Table>
    )
}

export default InputTable
