import React from 'react';
import {Card, CardTitle, CardSubtitle, CardText} from 'reactstrap';
import {precise} from '../table/helpers';
import {convertToPercent} from '../helpers';
import {NOTE_RATIO} from '../constants';

const FragranceCard = (props) => {
    // TODO: add informatino about the ratios we apply for the fragrances:
    // ie 15 / 25 / 60 or -- whatever is in `<Assumptions`
    const {noteAmount, title, defaultTitle, unit} = props;
    return (
        <Card body>
            <CardTitle tag="h3">
                {title || defaultTitle}: {precise(noteAmount)} {unit}
            </CardTitle>
            {defaultTitle && (
                <CardSubtitle tag="h6">{defaultTitle}</CardSubtitle>
            )}
            <br />
            <CardText>
                <p>
                    Add{' '}
                    <b>
                        {precise(noteAmount)} {unit}
                    </b>{' '}
                    of {title || ''} fragrance oil. {props.emoji}
                </p>
                {props.children}
            </CardText>
        </Card>
    );
};

export const TopFragranceCard = (props) => (
    <FragranceCard {...props} defaultTitle="Top note" emoji="😁">
        <small>
            <i>
                The top note is the first scent that people will smell. Top
                notes form one's initial impression of a candle and should be
                purposefully selected. The top note will be{' '}
                {convertToPercent(NOTE_RATIO.HIGH)} of total fragrance oil
                amount.
            </i>
        </small>
    </FragranceCard>
);

export const MidFragranceCard = (props) => (
    <FragranceCard {...props} defaultTitle="Mid note" emoji="😃">
        <small>
            <i>
                The mid note is the scent that emerges right after the top note
                dissipates. The mid note forms the body of the candle, helping
                mask any unpleasantries of the top note, transitioning the
                candle to a more mellowed state. The mid note will be{' '}
                {convertToPercent(NOTE_RATIO.MID)} of total fragrance oil
                amount.
            </i>
        </small>
    </FragranceCard>
);

export const BaseFragranceCard = (props) => (
    <FragranceCard {...props} defaultTitle="Base note" emoji="🙂">
        <small>
            <i>
                The base note is what brings depth to your candle. The molecules
                of this class tend to be heavier, evaporate slower, and are not
                noticeable until at least 30 minutes of burn time. The base note
                will be {convertToPercent(NOTE_RATIO.BASE)} of total fragrance
                oil amount.
            </i>
        </small>
    </FragranceCard>
);

export default FragranceCard;
