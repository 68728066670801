import React from 'react'

const Headers = (props) => {
    return (
        <thead>
            <tr>
                <th>#</th>
                <th>Name</th>
                <th>Price Range</th>
                <th>Rating</th>
                <th>Soy Performance</th>
                <th>Search Volume</th>
            </tr>
        </thead>
    )
}

export default Headers
