import React from 'react';
import _ from 'lodash';
import { Label, Input, InputGroup, InputGroupText } from 'reactstrap';
import { ErrorText, pluralizeCandle } from './common';
import {
    InputDispatchContext,
    InputStateContext,
} from '../contexts/InputContext';
import { UPDATE_ACTION } from '../reducer';

const TypeOfCandleInput = (props) => {
    const dispatch = React.useContext(InputDispatchContext);
    const { typeOfCandle, errors } = React.useContext(InputStateContext);
    const error = _.get(errors, 'typeOfCandle');
    return (
        <>
            <Label for="type-of-cnadle-input">
                <h5>Vessel size:</h5>
                <small>
                    <i>
                        Unit of candle in oz. You'd enter <b>8</b> if you were
                        making 8oz candles.
                    </i>
                </small>
            </Label>
            <InputGroup>
                <Input
                    type="number"
                    name="type-of-candle-input"
                    id="type-of-candle-input"
                    bsSize="small"
                    onChange={(e) => {
                        dispatch({
                            type: UPDATE_ACTION,
                            payload: {
                                key: 'typeOfCandle',
                                value: e.target.value,
                            },
                        });
                    }}
                    value={typeOfCandle}
                    placeholder="Enter vessel size"
                />
                <InputGroupText>oz</InputGroupText>
            </InputGroup>
            {error ? <ErrorText text={error} /> : null}
            <br />
        </>
    );
};

export default TypeOfCandleInput;
