import React from 'react';
import { Link, graphql } from 'gatsby';

import Layout from '../components/layout';
import SEO from '../components/seo';
import { rhythm, scale } from '../utils/typography';
import LinkStylesheets from '../components/LinkStylesheets';

import rehypeReact from 'rehype-react';
import CenteredPTag from '../custom/centeredptag';
import Etsy from '../custom/Etsy';
import CandleScienceSV from '../custom/CandleScienceSV';
import NewFeatureBanner from '../custom/NewFeatureBanner';
import FeatureRequestForm from '../custom/FeatureRequestForm';
import MailChimpJS from '../integrations/mailChimp';
import DonationTable from '../custom/DonationTable';
import FragrancePricing from '../custom/FragrancePricing';
import Base from './base';
import LoginButton from '../components/LoginButton';
import { SocialHandles } from '../components/bio';
import { TrackClick } from '../components/TrackClick';
import FragranceCalculator from '../custom/FragranceCalculator';

const renderAst = new rehypeReact({
    createElement: React.createElement,
    components: {
        'center-p': CenteredPTag,
        'fragrance-calculator': FragranceCalculator,
        etsy: Etsy,
        'candlescience-sv': CandleScienceSV,
        'new-feature-banner': NewFeatureBanner,
        'feedback-form': FeatureRequestForm,
        'donation-table': DonationTable,
        'fragrance-pricing': FragrancePricing,
        // "my-component": Calculator, // this is a test
        // inside of here, we can list our components (?)
        'login-button': LoginButton,
        'social-handles': SocialHandles,
        'track-click': TrackClick,
    },
}).Compiler;

const BlogPostTemplate = ({ data, pageContext, location }) => {
    const post = data.markdownRemark;
    const siteTitle = data.site.siteMetadata.title;
    const { previous, next } = pageContext;

    return (
        <Base>
            <Layout location={location} title={siteTitle}>
                <LinkStylesheets />
                <SEO
                    title={post.frontmatter.title}
                    description={post.frontmatter.description || post.excerpt}
                />
                <article>
                    <header>
                        <h1
                            style={{
                                marginTop: rhythm(1),
                                marginBottom: 0,
                                fontFamily: `athelas, serif`,
                            }}
                        >
                            {post.frontmatter.title}
                        </h1>
                        <p
                            style={{
                                ...scale(-1 / 5),
                                display: `block`,
                                marginBottom: rhythm(1),
                            }}
                        >
                            {post.frontmatter.date} |{' '}
                            {post.frontmatter.duration} read
                        </p>
                    </header>
                    <section>{renderAst(post.htmlAst)}</section>
                    <hr
                        style={{
                            marginBottom: rhythm(1),
                        }}
                    />
                    {/* <footer>*/}
                    {/*    <Bio />*/}
                    {/* </footer>*/}
                </article>

                <MailChimpJS />

                <nav>
                    <ul
                        style={{
                            display: `flex`,
                            flexWrap: `wrap`,
                            justifyContent: `space-between`,
                            listStyle: `none`,
                            padding: 0,
                        }}
                    >
                        <li>
                            {previous && (
                                <Link to={previous.fields.slug} rel="prev">
                                    ← {previous.frontmatter.title}
                                </Link>
                            )}
                        </li>
                        <li>
                            {next && (
                                <Link to={next.fields.slug} rel="next">
                                    {next.frontmatter.title} →
                                </Link>
                            )}
                        </li>
                    </ul>
                </nav>
            </Layout>
        </Base>
    );
};

export default BlogPostTemplate;

export const pageQuery = graphql`
    query BlogPostBySlug($slug: String!) {
        site {
            siteMetadata {
                title
            }
        }
        markdownRemark(fields: { slug: { eq: $slug } }) {
            id
            excerpt(pruneLength: 160)
            htmlAst
            frontmatter {
                title
                date(formatString: "MMMM DD, YYYY")
                description
                duration
            }
        }
    }
`;
