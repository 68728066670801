import React from 'react'
import _ from 'lodash'
import { useGraphApi } from '../api'

import CSTable from './CSTable'
import Loading from '../components/Loading'

const url =
    'https://us-central1-etsy-project-273203.cloudfunctions.net/candle_science_search_volume'

/*
TODO: Generalize table component. A table should be able to be generalized.

Functionality that the table should have:
	- Ordering within the headers when clicked
	- Pagination
	- Able to display a table
*/

const CandleScienceSV = (props) => {
    // need an input box here for the search state
    const { isLoading, data } = useGraphApi(url, [])
    const filteredData = data.filter((el) => el.rating && el.soy_performance)
    const retrievedData = isLoading
        ? []
        : _.orderBy(filteredData, ['search_volume', 'rating'], ['desc', 'desc'])

    if (isLoading) return <Loading />

    return <CSTable data={retrievedData} />
}

export default CandleScienceSV
