import React from 'react';
import { Button, ModalFooter, ModalHeader, Modal, ModalBody } from 'reactstrap';
import { useView } from './hooks';
import FragranceCalculations from '../Tabs/FragranceCalculations';

const ViewingModal = (props) => {
    const { data, isLoading } = useView(props.calculationUrl);
    const toggle = () => props.setIsViewing(!props.isOpen);

    if (isLoading) {
        // TODO: find a full-page loader that we can use
        return null;
    }

    console.log({ data });

    return (
        <Modal isOpen={props.isOpen} toggle={toggle} size="lg">
            <ModalHeader toggle={toggle}>
                {data.name ? `Viewing: ${data.name}` : ''}
            </ModalHeader>
            <ModalBody>
                <FragranceCalculations
                    numCandles={data.number_of_candles}
                    typeOfCandle={data.vessel_size}
                    ratio={data.fragrance_load_percentage}
                    candleName={data.name}
                    highNoteTitle={data.high_note_name}
                    midNoteTitle={data.mid_nite_name}
                    baseNoteTitle={data.base_note_name}
                    singleNoteTitle={data.single_note_name}
                    numNotes={data.num_notes}
                />
            </ModalBody>
            <ModalFooter>
                <Button color="secondary" onClick={toggle}>
                    Close
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export default ViewingModal;
