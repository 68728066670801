import React from 'react'
import NoteRow from './NoteRow'
import TotalRow from './TotalRow'

const SingleFragrance = (props) => {
    return (
        <>
            <NoteRow
                note="SING"
                quantity={props.quantity}
                waxToFragranceRatio={props.waxToFragranceRatio}
                title={props.singleNoteTitle}
            />
            <TotalRow
                quantity={props.quantity}
                waxToFragranceRatio={props.waxToFragranceRatio}
            />
        </>
    )
}

export default SingleFragrance
