import React from 'react';

// @ts-ignore
import FragranceCalculatorV1 from './calculators/FragranceCalculatorV1';
// @ts-ignore
import FragranceCalculatorV2 from './calculators/FragranceCalculatorV2';
import FragranceCalculatorV3 from './calculators/FragranceCalculatorV3';
import { FRAGRANCE_CALCULATOR_VERSIONS } from './constants';
import VersionSelector from './components/VersionSelector';
import { Badge } from 'reactstrap';

const FragranceCalculators = () => {
    const [version, setVersion] = React.useState(
        FRAGRANCE_CALCULATOR_VERSIONS.V3
    );

    let calculator = null;

    if (version === FRAGRANCE_CALCULATOR_VERSIONS.V1) {
        calculator = <FragranceCalculatorV1 />;
    } else if (version === FRAGRANCE_CALCULATOR_VERSIONS.V2) {
        calculator = <FragranceCalculatorV2 />;
    } else {
        calculator = <FragranceCalculatorV3 />;
    }

    return (
        <>
            <VersionSelector setVersion={setVersion} version={version} />
            <br />
            {version != FRAGRANCE_CALCULATOR_VERSIONS.V3 && (
                <div style={{ marginBottom: '1rem' }}>
                    <Badge color="danger">{version} is deprecated.</Badge>
                </div>
            )}
            {calculator}
        </>
    );
};

export default FragranceCalculators;
