import React from 'react'
import Select from 'react-select'
import { Label } from 'reactstrap'
import { InputDispatchContext, InputStateContext } from "../contexts/InputContext";
import { UPDATE_ACTION } from "../reducer";

const OPTION_LABELS = {
    // keys match with the OPTIONS[].value
    1: 'One fragrance',
    3: 'Three fragrances',
}

const OPTIONS = [
    {
        label: OPTION_LABELS[1],
        value: 1,
    },
    {
        label: OPTION_LABELS[3],
        value: 3,
    },
]

const NumNotesInput = () => {
    const dispatch = React.useContext(InputDispatchContext)
    const { numNotes } = React.useContext(InputStateContext);

    return (
        <>
            <Label for="num-notes">
                <h5>Number of fragrances:</h5>
                <small>
                    <i>
                        The number of fragrances you plan to use. If you tend to
                        mix fragrances, set this value to <b>three fragrances</b> (high/mid/base).
                        If you tend to stick with 1
                        fragrance, then set this to <b>one fragrance</b>.
                    </i>
                </small>
            </Label>
            <Select
                defaultValue={OPTIONS[0]}
                onChange={(event) => {
                    dispatch({
                        type: UPDATE_ACTION,
                        payload: {
                            key: 'numNotes',
                            value: event.value,
                        }
                    })
                }}
                value={{
                    label: OPTION_LABELS[numNotes || 1],
                    value: numNotes,
                }}
                options={OPTIONS}
            />
            <br />
        </>
    )
}

export default NumNotesInput
