import React from 'react'
import FragranceToWaxInput from './FragranceToWaxInput'
import NumCandlesInput from './NumCandlesInput'
import TypeOfCandleInput from './TypeOfCandleInput'

const Inputs = () => {
    return (
        <>
            <FragranceToWaxInput />
            <br />
            <NumCandlesInput />
            <br />
            <TypeOfCandleInput />
            <br />
        </>
    )
}

export default Inputs
