import React from 'react'

import EtsyGraph from './EtsyGraph'
import EtsyTable from './EtsyTable'
import './etsy_styles.css'

const Etsy = (props) => {
    const [toDate, setToDate] = React.useState()

    return (
        <>
            <EtsyGraph setToDate={setToDate} />
            <EtsyTable toDate={toDate} />
        </>
    )
}

export default Etsy
