import React from 'react';
import qs from 'query-string';
import Axios from 'axios';
import { Modal, ModalHeader, ModalBody, Table } from 'reactstrap';
import URLS from '../../FragranceCalculator/calculators/FragranceCalculatorV2/urls';
import { useQuery } from 'react-query';
import Loading from '../../components/Loading';
import { VENDOR_TYPE_MAP } from '../constants';
import { formatFragranceName } from '../helpers';

const getPricingDetails = (name, vendor) => {
    const url = URLS.fragrancePricingUrlDetails;
    const queryParams = qs.stringify({ vendor, name });
    return Axios.get(`${url}?${queryParams}`);
};

const PricingModal = (props) => {
    const { isOpen, toggle, name, vendor } = props;
    const { data, isLoading } = useQuery(
        `pricing-details-${name}-${vendor}`,
        () => getPricingDetails(name, vendor),
        {
            enabled: Boolean(name) && Boolean(vendor),
        }
    );

    // the additional `.data` is from axios
    const details = data?.data;

    // TODO: do a thing where we "add" it to a list of targeted/wanted fragrances

    return (
        <Modal size="lg" isOpen={isOpen} toggle={toggle}>
            <ModalHeader toggle={toggle}>
                {VENDOR_TYPE_MAP[vendor]}: {formatFragranceName(name)}
            </ModalHeader>
            <ModalBody>
                {isLoading && <Loading />}
                {!isLoading && details && (
                    <div>
                        {details.num_reviews ? (
                            <p>
                                Rating: <b>{details.rating}</b>{' '}
                                {`of ${details.num_reviews} reviews`}
                            </p>
                        ) : null}
                        {details.inventory && (
                            <Table bordered>
                                <tbody>
                                    {details.inventory.map((el) => {
                                        return (
                                            <tr
                                                key={`${el.size}-${el.price}-${details.name}-${details.date}`}
                                            >
                                                <td>Size: {el.size}</td>
                                                <td>${el.price}</td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </Table>
                        )}
                    </div>
                )}
            </ModalBody>
        </Modal>
    );
};

export default PricingModal;
