import Axios from 'axios';
import { useMutation, useQueryClient } from 'react-query';
import { useAlert } from 'react-alert';
import { GET_CALCULATIONS_KEY, URLS } from '../../../constants';
import { CalculationPostData } from '../../../types';

const CREATION_URL = URLS.fragranceCalculationUrl;

const cb = (calculationData: CalculationPostData) =>
    Axios.post(CREATION_URL, calculationData);

export const useCreation = () => {
    const queryClient = useQueryClient();
    const alert = useAlert();
    const { mutate, data } = useMutation(cb, {
        onSuccess: async () => {
            alert.success('Fragrance saved!');
            await queryClient.refetchQueries(GET_CALCULATIONS_KEY);
        },
        onError: () => {
            alert.error('There was an error. Please try again.');
        },
    });

    return {
        mutate,
        data,
    };
};
