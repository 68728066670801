import React from 'react';
import classnames from 'classnames';
import { Alert, Nav, TabContent, TabPane, Badge } from 'reactstrap';

import Inputs from './inputs';
import FragranceTable from './table';
import SelectedText from './SelectedText';
import Assumptions from './Assumptions';
import Directions from './Directions';
import { trackTabClick, useFragranceMonitoring } from './monitoring';
import { useDefaultParams } from './hooks';
import LastSearchAlert from './LastSearchAlert';
import LastSearchedProvider from './contexts/LastSearchedContext';
import GenerateUrlButton from './GenerateUrlButton';
import { canGenerateUrl } from './helpers';
import NewFeatureBanner from '../../../NewFeatureBanner';
import CalculatorNavItem from './NavItem';

const FragranceCalculator = (props) => {
    // I'm thinking here is where we would do a quick check against the url state.
    //
    // plus we should also look to add some validation here. If it's no good, then we should cut
    // default it back
    const {
        ratio,
        setRatio,
        numCandles,
        setNumCandles,
        typeOfCandle,
        setTypeOfCandle,
        highNoteTitle,
        setHighNoteTitle,
        midNoteTitle,
        setMidNoteTitle,
        baseNoteTitle,
        setBaseNoteTitle,
        numNotes,
        setNumNotes,
        singleNoteTitle,
        setSingleNoteTitle,
    } = useDefaultParams();

    // Note that this state var is __only__ used for the hook. It's used to track
    // whether we are on first load or not. Reason being is that all values are
    // actually filled out, so we would fire an analytics call even though the
    // user didn't do anything. We don't want to do this - so instead, we'll
    // track this in a state variable instead.
    const [isFirstAnalyticsLoad, setIsFirstAnalyticsLoad] =
        React.useState(false);
    const [stateUrl, setStateUrl] = React.useState('');

    useFragranceMonitoring({
        ratio,
        numCandles,
        typeOfCandle,
        highNoteTitle,
        midNoteTitle,
        baseNoteTitle,
        singleNoteTitle,
        numNotes,
        isFirstAnalyticsLoad,
        setIsFirstAnalyticsLoad,
    });

    const shouldGenerateUrl = canGenerateUrl(ratio, numCandles, typeOfCandle);

    const [activeTab, setActiveTab] = React.useState('1');

    return (
        <div>
            <LastSearchAlert />
            <Nav tabs>
                <CalculatorNavItem
                    activeTab={activeTab}
                    setActiveTab={setActiveTab}
                    tab="1"
                    tabName="Calculator"
                />
                <CalculatorNavItem
                    activeTab={activeTab}
                    setActiveTab={setActiveTab}
                    tab="2"
                    tabName="Directions"
                />
                <CalculatorNavItem
                    activeTab={activeTab}
                    setActiveTab={setActiveTab}
                    tab="3"
                    tabName="Report"
                />
            </Nav>
            <TabContent activeTab={activeTab}>
                <br />
                <br />
                <TabPane tabId="1">
                    <NewFeatureBanner color="warning">
                        <div style={{ fontSize: '0.90rem' }}>
                            The suggestions from this tool{' '}
                            <b>will not be perfect</b>. There are many other
                            factors that affect how wax behaves when heated, so
                            please treat this as a tool that guides rather than
                            a tool that guarantees.
                        </div>
                    </NewFeatureBanner>
                    <br />
                    <Inputs
                        ratio={ratio}
                        setRatio={setRatio}
                        numCandles={numCandles}
                        setNumCandles={setNumCandles}
                        typeOfCandle={typeOfCandle}
                        setTypeOfCandle={setTypeOfCandle}
                        highNoteTitle={highNoteTitle}
                        setHighNoteTitle={setHighNoteTitle}
                        midNoteTitle={midNoteTitle}
                        setMidNoteTitle={setMidNoteTitle}
                        baseNoteTitle={baseNoteTitle}
                        setBaseNoteTitle={setBaseNoteTitle}
                        numNotes={numNotes}
                        setNumNotes={setNumNotes}
                        setSingleNoteTitle={setSingleNoteTitle}
                        singleNoteTitle={singleNoteTitle}
                    />
                    {shouldGenerateUrl ? (
                        <SelectedText
                            ratio={ratio}
                            numCandles={numCandles}
                            typeOfCandles={typeOfCandle}
                        />
                    ) : null}
                    {shouldGenerateUrl && numNotes === 1 ? (
                        <Assumptions />
                    ) : null}
                    <br />
                    <br />
                    {shouldGenerateUrl && (
                        <FragranceTable
                            numNotes={numNotes}
                            ratio={ratio}
                            numCandles={numCandles}
                            typeOfCandle={typeOfCandle}
                            singleNoteTitle={singleNoteTitle}
                            highNoteTitle={highNoteTitle}
                            midNoteTitle={midNoteTitle}
                            baseNoteTitle={baseNoteTitle}
                        />
                    )}
                    <br />
                    <br />
                    {shouldGenerateUrl ? (
                        <GenerateUrlButton
                            singleNoteTitle={singleNoteTitle}
                            ratio={ratio}
                            numCandles={numCandles}
                            typeOfCandle={typeOfCandle}
                            highNoteTitle={highNoteTitle}
                            midNoteTitle={midNoteTitle}
                            baseNoteTitle={baseNoteTitle}
                            stateUrl={stateUrl}
                            setStateUrl={setStateUrl}
                        />
                    ) : null}
                </TabPane>
                <TabPane tabId="2">
                    <Directions />
                </TabPane>
                <TabPane tabId="3">
                    <div>
                        <h5>It's coming... 👀</h5>
                        <br />
                        <div>
                            A user provided great feedback about the possibility
                            of emailing the results of this tool. 📨
                        </div>
                        <br />
                        <div>
                            This got me thinking. Maybe a summarization of
                            results in PDF form could be helpful. 📋
                        </div>
                        <br />
                        <div>
                            If you are interested in providing feedback or
                            suggestions on such a report, please fill out the
                            feedback form below. You don't have to provide any
                            contact information if you don't want to.
                        </div>
                    </div>
                </TabPane>
            </TabContent>
        </div>
    );
};

const Main = (props) => (
    <LastSearchedProvider>
        <FragranceCalculator {...props} />
    </LastSearchedProvider>
);

export default Main;
