import React from 'react';
import { Button, ModalFooter, ModalHeader, Modal, ModalBody } from 'reactstrap';
import { useView } from './hooks';
import PlanToMakeText from '../Calculator/Results/PlanToMakeText';
import ResultsTableCard from '../Calculator/Results/Cards/ResultsTableCard';
import WaxCard from '../Calculator/Results/Cards/WaxCard';
import FragranceNotesCard from '../Calculator/Results/Cards/FragranceNotesCard';
import { getCalculations } from '../Calculator/Results/calculations';
// import FragranceCalculations from '../Tabs/FragranceCalculations';

type PropTypes = {
    calculationUrl: string;
    isOpen: boolean;
    setIsViewing: (v: boolean) => void;
};

const ViewingModal = (props: PropTypes) => {
    console.log(props.calculationUrl);
    const { data, isLoading } = useView(props.calculationUrl);
    const toggle = () => props.setIsViewing(!props.isOpen);

    if (isLoading) {
        // TODO: find a full-page loader that we can use
        return null;
    }

    const values = {
        numCandles: data.number_of_candles,
        fragranceLoadPercentage: data.fragrance_load_percentage,
        vesselSize: data.vessel_size,
        candleName: data.name,
        fragranceName: data.single_not_name, // TODO: figure out multi-later
    };
    const { waxAmount, fragranceAmount } = getCalculations(values);

    return (
        <Modal isOpen={props.isOpen} toggle={toggle} size="lg">
            <ModalHeader toggle={toggle}>
                {data.name ? `Viewing: ${data.name}` : ''}
            </ModalHeader>
            <ModalBody>
                <ResultsTableCard {...values} />
                <br />
                <WaxCard waxAmount={waxAmount} />
                <br />
                <FragranceNotesCard
                    fragranceAmount={fragranceAmount}
                    fragranceName={values.fragranceName}
                />
            </ModalBody>
            <ModalFooter>
                <Button color="secondary" onClick={toggle}>
                    Close
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export default ViewingModal;
