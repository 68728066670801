import React from 'react'

const ErrorPage = ({ data, location }) => {
    return (
      <div>
          You got into a broken workflow :(
          <div>
              Feel free to tweet me at @brianczhou for help. Sorry for the inconvenience!
          </div>
      </div>
    )
}

export default ErrorPage
