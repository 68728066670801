import React from 'react'
import { Tooltip } from 'reactstrap'

import { NOTE_RATIO } from './constants'

const convertToPercent = (value) => `${value * 100}%`

const Assumptions = (props) => {
    const [tooltipOpen, setTooltipOpen] = React.useState(false)
    const toggle = () => setTooltipOpen(!tooltipOpen)
    return (
        <>
            <i id="note-tooltip">
                Below figures are calculated with note ratios of high at{' '}
                {convertToPercent(NOTE_RATIO.HIGH)}, mid at{' '}
                {convertToPercent(NOTE_RATIO.MID)}, and base at{' '}
                {convertToPercent(NOTE_RATIO.BASE)}.
            </i>
            <Tooltip
                placement={'right'}
                target="note-tooltip"
                toggle={toggle}
                isOpen={tooltipOpen}
            />
        </>
    )
}

export default Assumptions
