import React from 'react'
import posed, { PoseGroup } from 'react-pose'

import Examples from './Examples'
import ExampleBtn from './ExampleBtn'

const TextDiv = posed.div({
    // hidden: { opacity: 0 },
    // visible: { opacity: 1 },
    enter: {
        opacity: 1,
        delay: 200,
        transition: {
            y: { type: 'spring', stiffness: 1000, damping: 15 },
        },
    },
    exit: {
        opacity: 0,
        transition: { duration: 150 },
    },
})

const Directions = React.memo(() => (
    <>
        <h3 style={{ textDecoration: 'underline' }}>Directions: </h3>
        <br />
        1. Make your selections for the three parameters (fragrance-to-wax, # of
        candles, type of candle)
        <br />
        <br />
        2. Measure fragrance oil based on table output
    </>
))

const DirectionsText = (props) => {
    return (
        <div>
            {/*Below is a fragrance calculator. I built this because I*/}
            {/*found it difficult to keep track how much fragrance oil I*/}
            {/*needed per batch. I also kept losing the stickies I was*/}
            {/*writing my ratios on. This fragrance calculator is meant to*/}
            {/*help to calculate ratios digitally and on the fly.*/}
            {/*<br />*/}
            {/*<br />*/}
            {/*<br />*/}
            <Directions />
            <br />
            <br />
            <br />
            <Examples />
            <br />
        </div>
    )
}

export default DirectionsText
