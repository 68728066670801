import React from 'react';
import { Input, InputGroup, InputGroupText, Label } from 'reactstrap';
import { Field, FieldProps, useField } from 'formik';
import {
    ErrorText,
    pluralizeCandle,
} from '../../../../FragranceCalculatorV2/inputs/commonTS';

const NumberCandlesInput = (fieldInputProps: FieldProps) => {
    return (
        <Input
            {...fieldInputProps.field}
            {...fieldInputProps.meta}
            type="number"
            bsSize="sm"
            placeholder="Enter number of candles"
        />
    );
};

const NumberCandles = () => {
    const [field, meta] = useField('numCandles');

    return (
        <>
            <Label for="numCandles">
                <h5>Number of candles:</h5>
                <small>
                    <i>
                        Quantity of candles you expect to make. Usually this
                        will be 1; unless you are making a large batch of one
                        fragrance composition (i.e. "I have an order of 10
                        strawberry candles").
                    </i>
                </small>
            </Label>
            <InputGroup>
                <Field name="numCandles" component={NumberCandlesInput} />
                <InputGroupText>{pluralizeCandle(field.value)}</InputGroupText>
            </InputGroup>
            <div>{meta.error ? <ErrorText text={meta.error} /> : null}</div>
            <br />
        </>
    );
};

export default NumberCandles;
