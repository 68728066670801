import React from 'react'
import { Alert } from 'reactstrap'

export default (props) => {
    const [showBanner, setShowBanner] = React.useState(true)
    return (
        <Alert
            color={props.color || 'info'}
            isOpen={showBanner}
            toggle={() => setShowBanner(false)}
        >
            {props.show_header && (
                <>
                    <h5>New Features:</h5>
                    <i>
                        <small>as of {props.as_of}</small>
                    </i>
                </>
            )}
            <div>{props.children}</div>
        </Alert>
    )
}
