import { buildPropertyString } from '../../../../services/amplitude';

export const trackTabClick = (tab: string) => {
    buildPropertyString({
        category: 'Candles',
        property: 'Fragrance Calculator',
        action: 'Clicked tab',
        properties: { tab },
    });
};
