import React from 'react';
import { Badge, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import { useAuth0Hook } from './hooks/useAuth0Hook';
import classNames from 'classnames';
import { TabNames } from './types';
import { trackTabClick } from './monitoring';
import NewFeatureBannerTS from '../../../NewFeatureBanner/NewFeatureBannerTS';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import FragranceCalculator from '../FragranceCalculatorV2';

import Calculations from './components/Calculations';
import NoLogin from './components/NoLogin';
import Calculator from './components/Calculator';

/*
What we need to do:
    * Outstanding Q’s:
        * Do people actually use the “multi-fragrance” option?
            - Well, we do, so we might need to still maintain it.
                - Try to build it so we can scale it to XX number of fragrances (?)

    TODO:
        - An "advanced section" that the user can hide
        - Multi-fragrance section? for me?
        - On the BE, make sure multiple submissions _don't_ get persisted
            - Not a biggie... since they can delete in the "My calculations" section
*/

const FragranceCalculatorV3 = () => {
    const [activeTab, setActiveTab] = React.useState(TabNames.Calculator);
    const { userId } = useAuth0Hook();

    return (
        <div>
            <Nav tabs>
                <NavItem>
                    <NavLink
                        className={classNames({
                            active: activeTab === TabNames.Calculator,
                            'cursor-hover': true,
                        })}
                        onClick={() => {
                            setActiveTab(TabNames.Calculator);
                        }}
                    >
                        <p>Calculator</p>
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink
                        className={classNames({
                            active: activeTab === TabNames.Calculations,
                            'cursor-hover': true,
                        })}
                        onClick={() => {
                            setActiveTab(TabNames.Calculations);
                            trackTabClick('Calculations tab');
                        }}
                    >
                        <p>
                            My calculations <Badge color="warning">BETA</Badge>
                        </p>
                    </NavLink>
                </NavItem>
            </Nav>
            <TabContent activeTab={activeTab}>
                <br />
                <NewFeatureBannerTS color="warning">
                    <div style={{ fontSize: '0.90rem' }}>
                        The suggestions from this tool{' '}
                        <b>will not be perfect</b>. There are many factors that
                        affect how wax behaves when heated, so please treat this
                        as a tool that guides rather than a tool that
                        guarantees.
                    </div>
                </NewFeatureBannerTS>
                <br />
                <TabPane tabId={TabNames.Calculator}>
                    <br />
                    <Calculator />
                </TabPane>
                <TabPane tabId={TabNames.Calculations}>
                    <div>calculations</div>
                    {userId ? <Calculations userId={userId} /> : <NoLogin />}
                </TabPane>
            </TabContent>
        </div>
    );
};

export default FragranceCalculatorV3;
