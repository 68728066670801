import React from 'react';
import { Card, CardBody, CardTitle } from 'reactstrap';
import { precise } from '../helpers';
import { UNIT_GRAM } from '../../constants';

type PropTypes = {
    waxAmount: number;
};

const WaxCard = (props: PropTypes) => {
    return (
        <Card style={{ backgroundColor: '#F7ECDE' }}>
            <CardBody>
                <CardTitle tag="h3">
                    Wax: {precise(props.waxAmount)} {UNIT_GRAM}
                </CardTitle>
                <CardTitle>
                    <p>
                        Prepare{' '}
                        <b>
                            {precise(props.waxAmount)} {UNIT_GRAM}
                        </b>{' '}
                        of wax to blend with your fragrance oils.
                    </p>
                    <small>
                        <i>
                            Different types of waxes (soy, paraffin, beeswax,
                            etc.) will yield different results. Be sure to do
                            your research to determine which wax works best for
                            you.
                        </i>
                    </small>
                </CardTitle>
            </CardBody>
        </Card>
    );
};

export default WaxCard;
