const buildConfig = (data, pointClick) => {
    /*
	data is going to have a schema of:
	{
		x: datetime,
		y: value
	}
	*/
    return {
        chart: {
            zoomType: 'x',
        },
        title: {
            text: 'Etsy score over time',
        },
        xAxis: {
            type: 'datetime',
        },
        yAxis: {
            title: {
                text: 'Score',
            },
        },
        legend: {
            enabled: false,
        },
        plotOptions: {
            area: {
                marker: {
                    radius: 2,
                },
                lineWidth: 1,
                states: {
                    hover: {
                        lineWidth: 1,
                    },
                },
                threshold: null,
            },
            series: {
                point: {
                    events: {
                        click: pointClick,
                    },
                },
            },
        },

        series: [
            {
                type: 'area',
                name: 'Etsy score',
                data: data,
            },
        ],
        credits: false,
    }
}

export default buildConfig
