import React from 'react';
import _ from 'lodash';
import { Label, Input, InputGroup, InputGroupText } from 'reactstrap';
import { ErrorText } from './common';
import {
    InputDispatchContext,
    InputStateContext,
} from '../contexts/InputContext';
import { UPDATE_ACTION } from '../reducer';

const FragranceToWaxInput = (props) => {
    const dispatch = React.useContext(InputDispatchContext);
    const { ratio, errors } = React.useContext(InputStateContext);
    const error = _.get(errors, 'ratio');
    return (
        <>
            <Label for="frag-to-wax-ratio">
                <h5>Fragrance load percentage:</h5>
                <small>
                    <i>
                        Percentage of fragrance oil used per candle. If you
                        wanted to have a fragrance load percentage of <b>10%</b>{' '}
                        you'd enter 10.{' '}
                        <a
                            href="https://support.candlescience.com/hc/en-us/articles/201352174-How-much-fragrance-oil-should-I-add-to-melted-wax-"
                            target="_blank"
                        >
                            Candlescience
                        </a>{' '}
                        has a good article on determining this ratio.
                    </i>
                </small>
            </Label>
            <InputGroup>
                <Input
                    type="number"
                    name="select"
                    id="frag-to-wax-ratio"
                    bsSize="med"
                    value={ratio}
                    onChange={(e) => {
                        dispatch({
                            type: UPDATE_ACTION,
                            payload: {
                                key: 'ratio',
                                value: e.target.value,
                            },
                        });
                    }}
                    placeholder="Enter fragrance load percentage"
                />
                <InputGroupText>%</InputGroupText>
            </InputGroup>
            <div>{error ? <ErrorText text={error} /> : null}</div>
            <br />
        </>
    );
};

export default FragranceToWaxInput;
