import React from 'react'

const CoreExample = React.memo(() => (
    <>
        Let's start by breaking down the candle order.
        <ol style={{ padding: '2rem' }}>
            <li>
                We need to make <b>10 candles</b>. In the{' '}
                <i>Number of candles</i> section, enter{' '}
                <span style={{ textDecoration: 'underline' }}>10</span>.
            </li>
            <li>
                These 10 candles are going to be in <b>8-oz</b> vessels. In the{' '}
                <i>Vessel size</i> section, enter{' '}
                <span style={{ textDecoration: 'underline' }}>8</span>.
            </li>
            <li>
                We want to ensure a strong hot throw, so we're going to be more
                aggressive in our fragrance load percentage and have it be{' '}
                <b>11%</b>. In the <i>Fragrance load percentage</i> section,
                enter <span style={{ textDecoration: 'underline' }}>11</span>.
            </li>
            <li>
                The input section should look like:
                <br />
                <img src="/scent-calculator/fragrance_inputs.png" />
            </li>
        </ol>
        <b>and what you'll see on the screen should be</b>
        <img src="/scent-calculator/fragrance_table.png" />
        <br />
    </>
))

export default CoreExample
