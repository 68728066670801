import React from 'react';
import { useFormikContext } from 'formik';
import PlanToMakeText from './PlanToMakeText';
import { getCalculations } from './calculations';
import SaveCalculationBox from './SaveCalculationBox';
import { useAuth0Hook } from '../../../hooks/useAuth0Hook';
import ResultsTableCard from './Cards/ResultsTableCard';
import WaxCard from './Cards/WaxCard';
import FragranceNotesCard from './Cards/FragranceNotesCard';
import { InputValuesType } from '../../../types';
import Confetti from 'react-confetti';
import { useWindowSize } from 'react-use';

const Results = () => {
    const { values, isValid } = useFormikContext<InputValuesType>();
    const [showConfetti, setShowConfetti] = React.useState(false);
    const { width, height } = useWindowSize();

    const { waxAmount, fragranceAmount } = getCalculations(values);
    const {
        auth0Values: { isAuthenticated },
        userId,
    } = useAuth0Hook();

    const hasAllValues =
        values.numCandles &&
        values.fragranceLoadPercentage &&
        values.vesselSize;

    if (!hasAllValues || !isValid) return null;

    return (
        <>
            {showConfetti && (
                <Confetti
                    width={width}
                    height={height}
                    numberOfPieces={500}
                    recycle={false}
                    onConfettiComplete={() => setShowConfetti(false)}
                />
            )}
            <PlanToMakeText {...values} />
            <br />
            <ResultsTableCard {...values} />
            <br />
            <WaxCard waxAmount={waxAmount} />
            <br />
            <FragranceNotesCard
                fragranceAmount={fragranceAmount}
                fragranceName={values.fragranceName}
            />
            <br />
            <br />
            <SaveCalculationBox
                isAuthenticated={isAuthenticated}
                userId={userId}
                {...values}
                postSaveCallback={() => {
                    setShowConfetti(true);
                    window.scrollTo({
                        top: 0,
                        behavior: 'smooth',
                    });
                }}
            />
        </>
    );
};

export default Results;
