import React from 'react'
import classnames from 'classnames'
import { trackTabClick } from './monitoring'
import { NavLink, NavItem } from 'reactstrap'

const CalculatorNavItem = (props) => (
    <NavItem>
        <NavLink
            className={classnames({
                active: props.activeTab === props.tab,
                'cursor-hover': true,
            })}
            onClick={() => {
                props.setActiveTab(props.tab)
                trackTabClick(props.tab.toLowerCase())
            }}
        >
            {props.tabName}
        </NavLink>
    </NavItem>
)

export default CalculatorNavItem
