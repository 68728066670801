import React from 'react'
import { buildPropertyString } from '../services/amplitude'
import { Alert } from 'reactstrap'

const FeatureRequestForm = () => {
    const [showBanner, setShowBanner] = React.useState(true)
    const cb = () => {
        // make an analytics call here
        buildPropertyString({
            category: 'Candles',
            property: 'Fragrance Calculator',
            action: 'Click on feedback form',
        })
        window.open('https://forms.gle/fdpWm4cp7zk7wfZu8', '_blank')
    }
    return (
        <Alert
            color="dark"
            isOpen={showBanner}
            toggle={() => setShowBanner(false)}
        >
            <div onClick={cb} className="cursor-hover">
                <span>
                    Got suggestions? Want to express your feedback? Fill out our
                    feature request form (click here)!
                </span>
            </div>
        </Alert>
    )
}

export default FeatureRequestForm
