import React from 'react'
import pluralize from 'pluralize'
import converter from 'number-to-words'

const SelectedText = (props) => {
    // const numAsWord = NUM_TO_WORD_MAP[props.numCandles];
    const numAsWord = converter.toWords(props.numCandles)
    const candlesText = pluralize('candle', Number(props.numCandles))
    return (
        <>
            <br />
            <br />
            <div
                style={{
                    // backgroundColor: '#a8e6cf',
                    padding: '2rem',
                    textAlign: 'center',
                }}
            >
                <h2>
                    You plan to make <u>{numAsWord}</u>{' '}
                    <u>{props.typeOfCandles} oz</u> {candlesText} with{' '}
                    a fragrance load percentage of <u>{props.ratio}%</u>.
                </h2>
            </div>
            <br />
            <br />
        </>
    )
}

export default SelectedText
