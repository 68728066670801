import React from 'react'
import { Button, Modal, ModalBody } from 'reactstrap'

import Cancel from './Cancel'
import DirectionsText from './DirectionsText'

const Directions = () => {
    return <DirectionsText />
}

export default Directions
