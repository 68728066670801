import React from 'react';
import { Nav, TabContent, TabPane, Badge, FormGroup, Form } from 'reactstrap';
import LastSearchedProvider from './contexts/LastSearchedContext';
import CalculatorNavItem from './NavItem';
import FragranceInputTab from './Tabs/FragranceInputTab';
import FragranceInfoTab from './Tabs/FragranceInfoTab';
import NewFeatureBanner from '../../../NewFeatureBanner';
import FragranceCalculations from './Tabs/FragranceCalculations';
import FooterButtons from './FooterButtons';
import InputProvider, { InputStateContext } from './contexts/InputContext';
import Calculations from './Calculations';

import './styles.scss';
import NoLogin from './NoLogin';
import { useAuth0Wrapper } from '../../../../components/LoginButton/hooks';

const FragranceCalculator = (props) => {
    const [activeTab, setActiveTab] = React.useState('1');
    const { isAuthenticated, user } = useAuth0Wrapper();

    const user_id = isAuthenticated ? user.sub : undefined;

    const state = React.useContext(InputStateContext);
    const { step } = state;

    const viewingCalculations = activeTab === '4';

    return (
        <div>
            <Nav tabs>
                <CalculatorNavItem
                    activeTab={activeTab}
                    setActiveTab={setActiveTab}
                    tab="1"
                    tabName="Calculator"
                />
                <CalculatorNavItem
                    activeTab={activeTab}
                    setActiveTab={setActiveTab}
                    tab="4"
                    tabName={
                        <p>
                            My calculations <Badge color="warning">BETA</Badge>
                        </p>
                    }
                />
            </Nav>
            <TabContent activeTab={activeTab}>
                <br />
                <NewFeatureBanner color="warning">
                    <div style={{ fontSize: '0.90rem' }}>
                        The suggestions from this tool{' '}
                        <b>will not be perfect</b>. There are many factors that
                        affect how wax behaves when heated, so please treat this
                        as a tool that guides rather than a tool that
                        guarantees.
                    </div>
                </NewFeatureBanner>
                <br />
                {/*<BetaTag setIsBeta={setIsBeta} />*/}
                <TabPane tabId="1">
                    <Form>
                        <FormGroup>
                            {step === 1 && <FragranceInputTab />}
                            {step === 2 && <FragranceInfoTab />}
                            {step === 3 && (
                                <FragranceCalculations
                                    {...state}
                                    showSave
                                    isAuthenticated
                                    user_id={user_id}
                                />
                            )}
                        </FormGroup>
                    </Form>
                </TabPane>
                <TabPane tabId="4">
                    {user ? <Calculations user_id={user_id} /> : <NoLogin />}
                </TabPane>
                {!viewingCalculations && <FooterButtons step={step} />}
            </TabContent>
        </div>
    );
};

const Main = (props) => (
    <InputProvider>
        <LastSearchedProvider>
            <FragranceCalculator {...props} />
        </LastSearchedProvider>
    </InputProvider>
);

export default Main;
