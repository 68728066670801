import React from 'react';
import { Input, InputGroup, InputGroupText, Label } from 'reactstrap';
import { Field, FieldProps, useField } from 'formik';
import {
    ErrorText,
    pluralizeCandle,
} from '../../../../FragranceCalculatorV2/inputs/commonTS';

const FragranceNameInput = (fieldInputProps: FieldProps) => {
    return (
        <Input
            {...fieldInputProps.field}
            {...fieldInputProps.meta}
            bsSize="sm"
            placeholder="Enter fragrance name"
        />
    );
};

const FragranceName = () => {
    const [field, meta] = useField('fragranceName');

    return (
        <>
            <Label for="fragranceName">
                <h5>Fragrance name:</h5>
                <small>
                    <i>
                        The scent you intend your candle to have (i.e.
                        Strawberry).
                    </i>
                </small>
            </Label>
            <InputGroup>
                <Field name="fragranceName" component={FragranceNameInput} />
                <InputGroupText>{pluralizeCandle(field.value)}</InputGroupText>
            </InputGroup>
            <div>{meta.error ? <ErrorText text={meta.error} /> : null}</div>
            <br />
        </>
    );
};

export default FragranceName;
