import React from 'react'
import { canGenerateUrl, generateUrl, getStateFromQueryParams } from './helpers'
import { LastSearchedDispatch } from './contexts/LastSearchedContext'

export const useDefaultParams = () => {
    const defaults = getStateFromQueryParams()

    const [numNotes, setNumNotes] = React.useState(defaults.numNotes)

    const [ratio, setRatio] = React.useState(defaults.fragranceToWaxRatio)
    const [numCandles, setNumCandles] = React.useState(defaults.numCandles)
    const [typeOfCandle, setTypeOfCandle] = React.useState(defaults.numCandles)

    // triple note
    const [highNoteTitle, setHighNoteTitle] = React.useState(defaults.highNote)
    const [midNoteTitle, setMidNoteTitle] = React.useState(defaults.midNote)
    const [baseNoteTitle, setBaseNoteTitle] = React.useState(defaults.baseNote)

    // singular note
    const [singleNoteTitle, setSingleNoteTitle] = React.useState(
        defaults.singleNote
    )

    const setCb = React.useContext(LastSearchedDispatch)
    React.useEffect(() => {
        // validate each section to see if we can save
        // and if we can -- then we'll set the callback
        const shouldGenerateUrl = canGenerateUrl(
            // TODO: add numNotes into here
            ratio,
            numCandles,
            typeOfCandle
        )
        if (shouldGenerateUrl) {
            const newUrl = generateUrl({
                // TODO: add numNotes into here
                ratio,
                numCandles,
                typeOfCandle,
                highNoteTitle,
                midNoteTitle,
                baseNoteTitle,
                numNotes,
                singleNoteTitle,
            })
            setCb(newUrl)
        }
    }, [
        // TODO: add numNotes into here
        ratio,
        numNotes,
        singleNoteTitle,
        numCandles,
        typeOfCandle,
        highNoteTitle,
        midNoteTitle,
        baseNoteTitle,
        setCb,
    ])

    return {
        ratio,
        setRatio: setRatio,
        numCandles,
        setNumCandles: setNumCandles,
        typeOfCandle,
        setTypeOfCandle: setTypeOfCandle,
        highNoteTitle,
        setHighNoteTitle: setHighNoteTitle,
        midNoteTitle,
        setMidNoteTitle: setMidNoteTitle,
        baseNoteTitle,
        setBaseNoteTitle: setBaseNoteTitle,
        numNotes,
        setNumNotes: setNumNotes,
        singleNoteTitle,
        setSingleNoteTitle: setSingleNoteTitle,
    }
}
