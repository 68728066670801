import React from 'react'
import { Button } from 'reactstrap'

import Cancel from '../../Cancel'
import MultiNoteNameInput from './MultiNoteNameInput'
import SingleNoteNameInput from './SingleNoteNameInput'

const NoteTypeInput = (props) => {
    const [showFragranceInput, setShowFragranceInput] = React.useState(false)
    const [hideAll, setHideAll] = React.useState(false)
    const btnText = showFragranceInput
        ? 'Hide section'
        : 'Attach fragrance oil names'

    if (hideAll) return null
    return (
        <>
            {showFragranceInput && (
                <div style={{ padding: '4rem' }}>
                    {props.numNotes === 3 ? (
                        <MultiNoteNameInput
                            setHighNoteTitle={props.setHighNoteTitle}
                            setMidNoteTitle={props.setMidNoteTitle}
                            setBaseNoteTitle={props.setBaseNoteTitle}
                        />
                    ) : (
                        <SingleNoteNameInput
                            singleNoteTitle={props.singleNoteTitle}
                            setSingleNoteTitle={props.setSingleNoteTitle}
                        />
                    )}{' '}
                </div>
            )}
            <div style={{ textAlign: 'center' }}>
                <Button
                    color="warning"
                    onClick={(e) => {
                        e.preventDefault()
                        setShowFragranceInput(!showFragranceInput)
                    }}
                    style={{
                        width: '50%',
                        display: 'inline-block',
                    }}
                >
                    <b>{btnText}</b>
                </Button>
                <Cancel cb={() => setHideAll(true)} />
            </div>
        </>
    )
}

export default NoteTypeInput
