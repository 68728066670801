import React from 'react';
import { Input, InputGroup, Label } from 'reactstrap';
import {
    InputDispatchContext,
    InputStateContext,
} from '../../contexts/InputContext';
import { UPDATE_ACTION } from '../../reducer';

const MultiNoteNameInput = (props) => {
    const dispatch = React.useContext(InputDispatchContext);
    const { highNoteTitle, midNoteTitle, baseNoteTitle } =
        React.useContext(InputStateContext);
    return (
        <>
            <Label for="candle-note-inputs">
                <h5>Fragrance name:</h5>
                <small>
                    <i>
                        The top note is the primary scent, and represents the
                        first impression.
                    </i>
                </small>
            </Label>
            <InputGroup>
                <Input
                    type="text"
                    name="candle-note-inputs"
                    id="cande-note-inputs"
                    bsSize="small"
                    value={highNoteTitle}
                    // placeholder="Vanilla"
                    onChange={(e) => {
                        dispatch({
                            type: UPDATE_ACTION,
                            payload: {
                                key: 'highNoteTitle',
                                value: e.target.value,
                            },
                        });
                    }}
                />
            </InputGroup>
            <br />
            <br />
            <small>
                <i>
                    The middle note composes the body of the scent. Think of
                    this as the scent you want to last.
                </i>
            </small>
            <InputGroup>
                <Input
                    type="text"
                    name="candle-note-inputs"
                    id="cande-note-inputs"
                    bsSize="small"
                    value={midNoteTitle}
                    // placeholder="Blood Orange"
                    onChange={(e) => {
                        dispatch({
                            type: UPDATE_ACTION,
                            payload: {
                                key: 'midNoteTitle',
                                value: e.target.value,
                            },
                        });
                    }}
                />
            </InputGroup>
            <br />
            <br />
            <small>
                <i>
                    The final fragrance. The lingering scent that you intend to
                    leave.
                </i>
            </small>
            <InputGroup>
                <Input
                    type="text"
                    name="candle-note-inputs"
                    id="cande-note-inputs"
                    bsSize="small"
                    // placeholder="Sandalwood"
                    value={baseNoteTitle}
                    onChange={(e) => {
                        dispatch({
                            type: UPDATE_ACTION,
                            payload: {
                                key: 'baseNoteTitle',
                                value: e.target.value,
                            },
                        });
                    }}
                />
            </InputGroup>
        </>
    );
};

export default MultiNoteNameInput;
