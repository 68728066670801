import React from 'react'
import { Card, CardBody, Col, Row } from 'reactstrap'

import AdditionalExample from './AdditionalExample'
import CoreExample from './CoreExample'
import ExampleBtn from './ExampleBtn'

const Examples = React.memo(() => {
    const SIMPLE = 'simple'
    const DETAILED = 'detailed'
    const [selection, setSelection] = React.useState(null)

    return (
        <>
            <h3 style={{ textDecoration: 'underline' }}>Examples: </h3>
            Someone wants to buy ten 8 oz candles for their candle-crazed uncle.
            <br />
            <br />
            <div>
                <Row>
                    <Col lg="1" />
                    <Col lg="4">
                        <ExampleBtn
                            color="black"
                            bgColor="rgb(168, 230, 207)"
                            onClick={() => setSelection(SIMPLE)}
                        >
                            Core usage
                        </ExampleBtn>
                    </Col>
                    <Col lg="1" />
                    <Col lg="1" />
                    <Col lg="4">
                        <ExampleBtn
                            color="black"
                            bgColor="rgb(241, 209, 209)"
                            onClick={() => setSelection(DETAILED)}
                        >
                            Additional usage
                        </ExampleBtn>
                    </Col>
                    <Col lg="1" />
                </Row>
            </div>
            {selection === SIMPLE && (
                <>
                    <br />
                    <br />
                    <Card>
                        <CardBody>
                            <CoreExample />
                        </CardBody>
                    </Card>
                </>
            )}
            {selection === DETAILED && (
                <>
                    <br />
                    <br />
                    <Card>
                        <CardBody>
                            <AdditionalExample />
                        </CardBody>
                    </Card>
                </>
            )}
            {!selection && (
                <>
                    <br />
                    <br />
                    <Card>
                        <CardBody>
                            <div
                                style={{ textAlign: 'center', padding: '2rem' }}
                            >
                                Select to view either a core flow example or an
                                additional usage example.
                            </div>
                        </CardBody>
                    </Card>
                    <br />
                </>
            )}
        </>
    )
})

export default Examples
