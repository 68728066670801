import React from 'react';
import { Input, InputGroup, InputGroupText, Label } from 'reactstrap';

const SingleNoteNameInput = (props) => {
    return (
        <>
            <Label for="single-candle-note-input">
                Below input isn't required for calculations; however, it makes
                the table easier to refer to when filled out.
            </Label>
            <InputGroup>
                <Input
                    type="text"
                    name="single-candle-note-input"
                    id="single-candle-note-input"
                    bsSize="small"
                    value={props.singleNoteTitle}
                    onChange={(e) => props.setSingleNoteTitle(e.target.value)}
                />
                <InputGroupText>Primary note fragrance</InputGroupText>
            </InputGroup>
        </>
    );
};

export default SingleNoteNameInput;
