export const DOMAIN = process.env.GATSBY_CANDLE_SERVICE_DOMAIN;

export const URLS = {
    fragranceCalculationUrl: `${DOMAIN}/fragrance-calculation`,
    loginFacebook: `${DOMAIN}/login/facebook`,
    fragrancePricingUrl: `${DOMAIN}/fragrance-pricing/v2`,
    fragrancePricingUrlDetails: `${DOMAIN}/fragrance-pricing/v2/details`,
};

export const GET_CALCULATIONS_KEY = 'GET_CALCULATIONS_KEY';

export const FL_OZ_TO_G = 29.57353;

export const DENSITY_SOYWAX = 0.9; // g/ml
