import React from 'react';
import Axios from 'axios';
import { Button } from 'reactstrap';
import { generateUrl } from './helpers';
import { trackGenerateLink } from './monitoring';
import { LastSearchedDispatch } from './contexts/LastSearchedContext';
import URLS from '../FragranceCalculatorV2/urls';

const NewGenerateUrlButton = (props) => {
    // TODO: This component is not yet down.
    // We are still missing properly handling the request due to
    // us not being able to capture the correct information for
    // the API request
    const {
        ratio,
        numCandles,
        typeOfCandle,
        highNoteTitle,
        midNoteTitle,
        baseNoteTitle,
        singleNoteTitle,
    } = props;

    return (
        <div
            style={{
                textAlign: 'center',
                padding: '1rem',
            }}
        >
            <p>Want to save this page for later?</p>
            <p>
                <Button
                    style={{
                        backgroundColor: '#f1d1d1',
                        color: 'black',
                        border: 'none',
                    }}
                    onClick={() => {
                        console.log(process.env);
                        const url = URLS.fragranceCalculationUrl;
                        const data = {
                            // number_of_fragrances,
                            // name
                            // user_id:
                            fragrance_load_percentage: ratio,
                            number_of_candles: numCandles,
                            vessel_size: typeOfCandle,
                            high_note_name: highNoteTitle,
                            mid_note_name: midNoteTitle,
                            base_note_name: baseNoteTitle,
                            single_note_name: singleNoteTitle,
                        };
                        Axios.post(url, data, {
                            headers: {
                                'Content-Type': 'application/json',
                            },
                        });
                    }}
                >
                    Click to generate link
                </Button>
            </p>
        </div>
    );
};

// OLD is where we simply generate a link. Now we'll take the user
// id and send it to the backend.
const GenerateUrlButton = (props) => {
    const setLastSearched = React.useContext(LastSearchedDispatch);
    const {
        ratio,
        numCandles,
        typeOfCandle,
        highNoteTitle,
        midNoteTitle,
        baseNoteTitle,
        stateUrl,
        setStateUrl,
    } = props;
    return (
        <div
            style={{
                textAlign: 'center',
                padding: '1rem',
            }}
        >
            <p>Want to save this page for later?</p>
            <p>
                <Button
                    style={{
                        backgroundColor: '#f1d1d1',
                        color: 'black',
                        border: 'none',
                    }}
                    onClick={() => {
                        const newUrl = generateUrl({
                            ratio,
                            numCandles,
                            typeOfCandle,
                            highNoteTitle,
                            midNoteTitle,
                            baseNoteTitle,
                        });
                        trackGenerateLink(newUrl);
                        setStateUrl(newUrl);
                        setLastSearched(newUrl);
                    }}
                >
                    Click to generate link
                </Button>
            </p>
            <a href={stateUrl}>{stateUrl}</a>
        </div>
    );
};

export default GenerateUrlButton;
