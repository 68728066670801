import React from 'react';
import pluralize from 'pluralize';
import converter from 'number-to-words';

type PropTypes = {
    numCandles: number;
    fragranceLoadPercentage: number;
    vesselSize: number;
    candleName: string;
};

const PlanToMakeText = (props: PropTypes) => {
    const numAsWord = converter.toWords(props.numCandles);
    const candlesText = pluralize('candle', Number(props.numCandles));
    return (
        <>
            <br />
            <br />
            <div
                style={{
                    // backgroundColor: '#a8e6cf',
                    padding: '2rem',
                    textAlign: 'center',
                }}
            >
                <h2>
                    You plan to make <u>{numAsWord}</u>{' '}
                    <u>{props.vesselSize} oz</u> <u>{props.candleName}</u>{' '}
                    {candlesText}, each with a fragrance load percentage of{' '}
                    <u>{props.fragranceLoadPercentage}%</u>.
                </h2>
            </div>
            <br />
            <br />
        </>
    );
};

export default PlanToMakeText;
