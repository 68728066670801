import React from 'react';
import JumbotronTS from '../../../../../components/JumbotronTS';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import LoginButton from '../../../../../components/LoginButton';

const NoLogin = () => {
    return (
        <div>
            <JumbotronTS>
                <h1>Introducing... </h1>
                <h6>the ability to save your calculations.</h6>
                <br />
                <p>
                    No more re-entering your candle numbers into the calculator.
                    Connect your Facebook so you can start saving your
                    calculations.
                </p>
                <hr />
                <a href="https://gyazo.com/a09bc9897520c84c2820620c4eb59e5d">
                    <img
                        src="https://i.gyazo.com/a09bc9897520c84c2820620c4eb59e5d.gif"
                        alt="Image from Gyazo"
                        width="1000"
                    />
                </a>
                <p className="center">
                    <LoginButton />
                </p>
            </JumbotronTS>
        </div>
    );
};

export default NoLogin;
