import React from 'react';
import _ from 'lodash';
import { Label, Input, InputGroup, InputGroupText } from 'reactstrap';
import { ErrorText, pluralizeCandle } from './common';
import {
    InputDispatchContext,
    InputStateContext,
} from '../contexts/InputContext';
import { UPDATE_ACTION } from '../reducer';

const NumCandlesInput = (props) => {
    const dispatch = React.useContext(InputDispatchContext);
    const { numCandles, errors } = React.useContext(InputStateContext);
    const error = _.get(errors, 'numCandles');
    return (
        <>
            <Label for="num-candles">
                <h5>Number of candles:</h5>
                <small>
                    <i>
                        Quantity of candles you expect to make. Usually this
                        will be 1; unless you are making a large batch of one
                        fragrance composition (i.e. "I have an order of 10
                        strawberry candles").
                    </i>
                </small>
            </Label>
            <InputGroup>
                <Input
                    type="number"
                    name="select"
                    id="num-candles"
                    bsSize="small"
                    value={numCandles}
                    onChange={(e) => {
                        dispatch({
                            type: UPDATE_ACTION,
                            payload: {
                                key: 'numCandles',
                                value: e.target.value,
                            },
                        });
                    }}
                    placeholder="Enter number of candles"
                />

                <InputGroupText>{pluralizeCandle(numCandles)}</InputGroupText>
            </InputGroup>
            <div>{error ? <ErrorText text={error} /> : null}</div>
            <br />
        </>
    );
};

export default NumCandlesInput;
