import React from 'react';

import { precise, applyConversion } from './helpers';

// TODO: add in a description for assumptions:
// 0.2 for high-note/ 0.35 for base/ 0.45 for base

const TotalRow = (props) => {
    return (
        <tr style={{ backgroundColor: '#cbe2b0' }}>
            <th scope="row">Totals</th>
            <td>
                <b>
                    {precise(
                        applyConversion({
                            quantity: props.quantity,
                            units: 'g',
                        })
                    )}{' '}
                    g
                </b>
            </td>
            <td>
                <b>
                    {precise(
                        applyConversion({
                            quantity: props.quantity,
                            units: 'ml',
                        })
                    )}{' '}
                    mL
                </b>
            </td>
            <td>
                <b>
                    {precise(
                        applyConversion({
                            quantity: props.quantity,
                            units: 'drops',
                        })
                    )}{' '}
                    drops
                </b>
            </td>
        </tr>
    );
};

export default TotalRow;
