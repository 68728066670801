import React from 'react';
import { Table } from 'reactstrap';

import { applyConversion, precise } from './helpers';
import MultiFragrance from './MultiFragrance';
import SingleFragrance from './SingleFragrance';
// have everything in relation to 1g

const GRAMS_TO_OUNCE = 0.0352; // 1 gram = 0.035 oz

const FragranceTable = (props) => {
    // multiply out the total quantity in oz, then find it in grams
    const quantity = (props.numCandles * props.typeOfCandle) / GRAMS_TO_OUNCE;
    const waxToFragranceRatio = props.ratio / 100;

    // total of fragrance oil + wax should be the Xoz of the candle.
    // i.e. for a 4oz candle, it should be: (0.9) * 4 of wax, and (0.1) * 4 of oil
    const waxQuantity = quantity * (1 - waxToFragranceRatio);

    return (
        <>
            <h5>Ratio table</h5>
            <Table bordered responsive>
                <thead>
                    <tr>
                        <th>Item</th>
                        <th>grams (g)</th>
                        <th>milileters (ml)</th>
                        <th>drops</th>
                    </tr>
                </thead>
                <tbody>
                    <tr style={{ backgroundColor: '#ecfbfc' }}>
                        <th scope="row">Wax</th>
                        <td>{precise(waxQuantity)} g</td>
                        <td>
                            {precise(
                                applyConversion({
                                    quantity: waxQuantity,
                                    units: 'ml',
                                })
                            )}{' '}
                            ml
                        </td>
                        <td>
                            {precise(
                                applyConversion({
                                    quantity: waxQuantity,
                                    units: 'drops',
                                })
                            )}{' '}
                            drops
                        </td>
                    </tr>
                    {props.numNotes === 3 ? (
                        <>
                            <MultiFragrance
                                quantity={quantity}
                                waxToFragranceRatio={waxToFragranceRatio}
                                highNoteTitle={props.highNoteTitle}
                                midNoteTitle={props.midNoteTitle}
                                baseNoteTitle={props.baseNoteTitle}
                            />
                        </>
                    ) : (
                        <SingleFragrance
                            quantity={quantity}
                            waxToFragranceRatio={waxToFragranceRatio}
                            singleNoteTitle={props.singleNoteTitle}
                        />
                    )}
                </tbody>
            </Table>
        </>
    );
};

export default FragranceTable;
