import qs from 'query-string'

export const LAST_SEARCHED_KEY = 'tcLastSearched'

const Q_PARAMS_F_TO_W_RATIO_KEY = 'fragranceToWaxRatio'
const Q_PARAMS_NUM_CANDLES_KEY = 'numCandles'
const Q_PARAMS_TYPE_OF_CANDLE_KEY = 'typeOfCandle'

export const parseLastSearchUrl = (url) => {
    const queryParams = url.split('?')[1] // separate out the queryParams from the rest of the url
    const parsed = qs.parse(queryParams)

    return {
        // camelcase keys
        [Q_PARAMS_F_TO_W_RATIO_KEY]: parsed['fragrance-to-wax-ratio'],
        [Q_PARAMS_NUM_CANDLES_KEY]: parsed['num-candles'],
        [Q_PARAMS_TYPE_OF_CANDLE_KEY]: parsed['type-of-candle'],
    }
}

export const sameLastSearchUrl = (url1, url2) => {
    const parsed1 = parseLastSearched(url1)
    const parsed2 = parseLastSearched(url2)

    return (
        parsed1[Q_PARAMS_F_TO_W_RATIO_KEY] ===
            parsed2[Q_PARAMS_F_TO_W_RATIO_KEY] &&
        parsed1[Q_PARAMS_NUM_CANDLES_KEY] ===
            parsed2[Q_PARAMS_NUM_CANDLES_KEY] &&
        parsed1[Q_PARAMS_TYPE_OF_CANDLE_KEY] ===
            parsed2[Q_PARAMS_TYPE_OF_CANDLE_KEY]
    )
}

export const validateLastSearch = (lastSearch) => {
    try {
        if (!lastSearch) return false

        // need to parse
        const parsed = parseLastSearchUrl(lastSearch)

        return (
            parsed[Q_PARAMS_F_TO_W_RATIO_KEY] &&
            parsed[Q_PARAMS_NUM_CANDLES_KEY] &&
            parsed[Q_PARAMS_TYPE_OF_CANDLE_KEY]
        )
    } catch (err) {
        return false
    }
}

export const parseLastSearched = (lastSearched) => {
    try {
        const parsed = parseLastSearchUrl(lastSearched)

        const fragranceToWaxRatio = parsed[Q_PARAMS_F_TO_W_RATIO_KEY]
        const numCandles = parsed[Q_PARAMS_NUM_CANDLES_KEY]
        const typeOfCandle = parsed[Q_PARAMS_TYPE_OF_CANDLE_KEY]

        return {
            fragranceToWaxRatio,
            numCandles,
            typeOfCandle,
        }
    } catch (err) {
        console.log(err)
        return {} // return empty object
    }
}

export const wrappedSetLastSearched = (newUrl, setCb) => {
    try {
        if (typeof window !== `undefined`) {
            // first clear what's existing
            window.localStorage.removeItem(LAST_SEARCHED_KEY)

            window.localStorage.setItem(LAST_SEARCHED_KEY, newUrl)
            setCb(newUrl)
        }
    } catch (err) {}
}
