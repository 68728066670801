export const FRAGRANCE_TO_WAX_RATIO_CHOICES = [6, 10, 12];
export const NUM_CANDLE_CHOICES = [1, 2, 3, 4, 5, 6]; // TODO: make it larger later
export const TYPE_OF_CANDLE_CHOICES = [2, 4, 6, 8];

export const TO_GRAM_CONVERTER = {
    g: 1,
    ml: 1,
    oz: 0.0352,
    drops: 20,
};

export const NOTE_RATIO = {
    HIGH: 0.2,
    MID: 0.35,
    BASE: 0.45,
    SING: 1, // singular note
};

export const READABLE_NOTE_MAP = {
    HIGH: 'High',
    MID: 'Mid',
    BASE: 'Base',
    SING: 'Primary',
};

export const FRAGRANCE_TO_WAX_RATIO_QUERY_PARAM = 'fragrance-to-wax-ratio';
export const NUM_CANDLE_QUERY_PARAM = 'num-candles';
export const TYPE_OF_CANDLE_QUERY_PARAM = 'type-of-candle';
export const HIGH_NOTE_QUERY_PARAM = 'high-note';
export const MID_NOTE_QUERY_PARAM = 'mid-note';
export const BASE_NOTE_QUERY_PARAM = 'base-note';
export const NUM_NOTE_QUERY_PARAM = 'num-notes';
export const SINGLE_NOTE_QUERY_PARAM = 'single-note';

// what this means is that the # of notes to be selected
// are 1 or 3
export const NUM_NOTE_SELECTIONS = [1, 3];
