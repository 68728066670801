import React from 'react'
import { Alert } from 'reactstrap'
import converter from 'number-to-words'
import pluralize from 'pluralize'

import {
    LastSearchedContext,
    LastSearchedDispatch,
} from './contexts/LastSearchedContext'
import { trackClickedLastSearchLink } from './monitoring'
import { parseLastSearched, sameLastSearchUrl } from './contexts/utils'

const LastSearchAlert = () => {
    const [showBanner, setShowBanner] = React.useState(true)

    const lastSearched = React.useContext(LastSearchedContext)
    const setCb = React.useContext(LastSearchedDispatch)

    if (!lastSearched) return null

    const { fragranceToWaxRatio, numCandles, typeOfCandle } = parseLastSearched(
        lastSearched
    )
    if (!fragranceToWaxRatio || !numCandles || !typeOfCandle) return null

    if (typeof window !== `undefined`) {
        // Need this for gatsby SSR
        // user is _already_ on this page. don't have them reload it.
        if (lastSearched == window.document.location.href) return null

        // sometimes above string equality fails -- so instead, check properties.
        const haSameLastSearchUrl = sameLastSearchUrl(
            lastSearched,
            window.document.location.href
        )
        if (haSameLastSearchUrl) return null
    }

    return (
        <Alert
            isOpen={showBanner}
            toggle={() => {
                setShowBanner(false)

                // also reset the callback.
                setCb('')
            }}
            style={{ cursor: 'pointer' }}
        >
            <div
                onClick={() => {
                    // track if clicked
                    trackClickedLastSearchLink(lastSearched)
                    window.location = lastSearched
                }}
            >
                <small>
                    Your last completed calculation was for{' '}
                    <b>
                        {converter.toWords(numCandles)} {typeOfCandle} oz{' '}
                        {pluralize('candle', numCandles)}
                    </b>
                    , each with a{' '}
                    <b>fragrance-to-wax percentage of {fragranceToWaxRatio}%</b>{' '}
                    .
                </small>
                <br />
                <small>
                    Click this banner to reload your last calculation.
                </small>
            </div>
        </Alert>
    )
}

export default LastSearchAlert
