import React from 'react'
import MultiNoteNameInput from './MultiNoteNameInput'
import SingleNoteNameInput from './SingleNoteNameInput'
import { InputStateContext } from '../../contexts/InputContext'

const NoteTypeInput = () => {
    const { numNotes } = React.useContext(InputStateContext)
    return (
        <>
            {numNotes === 3 ? <MultiNoteNameInput /> : <SingleNoteNameInput />}{' '}
        </>
    )
}

export default NoteTypeInput
