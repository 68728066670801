import { NOTE_RATIO, TO_GRAM_CONVERTER } from '../constants'

export const precise = (x) => {
    return Number.parseFloat(x).toPrecision(4)
}

export const applyConversion = ({ quantity, units }) => {
    return quantity * TO_GRAM_CONVERTER[units]
}

export const applyNote = ({ quantity, units, note }) => {
    return NOTE_RATIO[note] * applyConversion({ quantity, units })
}
