let amplitudeInstance;

export const logAmplitude = (eventString, properties) => {
    // We are using https://www.gatsbyjs.org/packages/gatsby-plugin-amplitude-analytics/ to
    // manage our amplitude analytics.
    try {
        window.envIsSet = false;
        if (!amplitudeInstance && window.amplitude) {
            if (typeof process.env === 'object') {
                window.env = process.env;
                window.envIsSet = true;
            }

            amplitudeInstance = window.amplitude.getInstance();
        }

        if (amplitudeInstance) {
            if (properties && Object.keys(properties).length) {
                amplitudeInstance.logEvent(eventString, properties);
            } else {
                amplitudeInstance.logEvent(eventString);
            }
        }
    } catch (err) {
        // TODO: add in code for Sentry front-end
        console.log(err);
    }
};

export const buildPropertyString = ({
    category,
    property,
    action,
    properties,
}) => logAmplitude(`${category} : ${property} : ${action}`, properties);
