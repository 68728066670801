import React from 'react'
import { Label, InputGroup, Input } from 'reactstrap'
import {
    InputDispatchContext,
    InputStateContext,
} from '../contexts/InputContext'
import { UPDATE_ACTION } from "../reducer";

const CandleNameInput = () => {
    const { candleName } = React.useContext(InputStateContext)
    const dispatch = React.useContext(InputDispatchContext)
    return (
        <>
            <Label for="candle-name">
                <h5>Candle name:</h5>
                <small>
                    <i>
                        The name of your candle (i.e. Lavender & Vanilla, or
                        Forest).
                    </i>
                </small>
            </Label>
            <InputGroup>
                <Input
                    type="candle-name"
                    id="candle-name"
                    bsSize="med"
                    value={candleName}
                    onChange={(e) => {
                        dispatch({
                            type: UPDATE_ACTION,
                            payload: {
                                key: 'candleName',
                                value: e.target.value,
                            }
                        })
                    }}
                />
            </InputGroup>
        </>
    )
}

export default CandleNameInput
