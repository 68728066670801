import React from 'react';

import {applyNote} from '../table/helpers';
import {NOTE_BASE, NOTE_HIGH, NOTE_MID} from '../constants';
import {
    BaseFragranceCard,
    MidFragranceCard,
    TopFragranceCard,
} from './FragranceCard';


const MultiFragranceCard = (props) => {
    const highNoteAmount =
        props.waxToFragranceRatio *
        applyNote({
            quantity: props.quantity,
            units: props.unit,
            note: NOTE_HIGH,
        });
    const midNoteAmount =
        props.waxToFragranceRatio *
        applyNote({
            quantity: props.quantity,
            units: props.unit,
            note: NOTE_MID,
        });
    const baseNoteAmount =
        props.waxToFragranceRatio *
        applyNote({
            quantity: props.quantity,
            units: props.unit,
            note: NOTE_BASE,
        });
    return (
        <>
            <TopFragranceCard
                noteAmount={highNoteAmount}
                title={props.highNoteTitle}
                unit={props.unit}
            />
            <br />
            <MidFragranceCard
                noteAmount={midNoteAmount}
                title={props.midNoteTitle}
                unit={props.unit}
            />
            <br />
            <BaseFragranceCard
                noteAmount={baseNoteAmount}
                title={props.baseNoteTitle}
                unit={props.unit}
            />
        </>
    );
};

export default MultiFragranceCard;
