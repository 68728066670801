import React from 'react';
import { Input, InputGroup, InputGroupText, Label } from 'reactstrap';

const MultiNoteNameInput = (props) => {
    return (
        <>
            <Label for="candle-note-inputs">
                The below inputs aren't required for calculations; however, they
                make the table easier to refer to when filled out.
            </Label>
            <InputGroup>
                <Input
                    type="text"
                    name="candle-note-inputs"
                    id="cande-note-inputs"
                    bsSize="small"
                    value={props.highNoteTitle}
                    placeholder="Note name. i.e. Vanilla"
                    onChange={(e) => props.setHighNoteTitle(e.target.value)}
                />
                <InputGroupText>High note fragrance</InputGroupText>
            </InputGroup>
            <br />
            <InputGroup>
                <Input
                    type="text"
                    name="candle-note-inputs"
                    id="cande-note-inputs"
                    bsSize="small"
                    value={props.midNoteTitle}
                    placeholder="Note name. i.e. Blood Orange"
                    onChange={(e) => props.setMidNoteTitle(e.target.value)}
                />
                <InputGroupText>Mid note fragrance</InputGroupText>
            </InputGroup>
            <br />
            <InputGroup>
                <Input
                    type="text"
                    name="candle-note-inputs"
                    id="cande-note-inputs"
                    bsSize="small"
                    placeholder="Note name. i.e. Sandalwood"
                    value={props.baseNoteTitle}
                    onChange={(e) => props.setBaseNoteTitle(e.target.value)}
                />
                <InputGroupText>Base note fragrance</InputGroupText>
            </InputGroup>
        </>
    );
};

export default MultiNoteNameInput;
