export const UPDATE_ACTION = 'UPDATE';
export const UPDATE_ERRORS = 'UPDATE_ERRORS';
export const MOVE_STEP = 'MOVE_STEP';

export const inputReducer = (state, action) => {
    switch (action.type) {
    case UPDATE_ACTION: {
        return {
            ...state,
            [action.payload.key]: action.payload.value,
            errors: {
                // reset errors once they start typing
                ...state.errors,
                [action.payload.key]: undefined,
            },
        };
    }
    case UPDATE_ERRORS: {
        return {
            ...state,
            errors: {
                ...action.payload.errors,
            },
        };
    }
    case MOVE_STEP: {
        return {
            ...state,
            step: action.payload.value,
        };
    }
    default: {
        return state;
    }
    }
};
