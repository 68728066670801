import React from 'react'

export const useGraphApi = (url, defaultData) => {
    const [hasFailed, setHasFailed] = React.useState(false)
    const [isLoading, setIsLoading] = React.useState(false)
    const [data, setData] = React.useState(defaultData)

    // let isMounted = true;

    React.useEffect(() => {
        let isMounted = true
        const fetchUrl = async () => {
            if (!url) return {}
            try {
                setIsLoading(true)
                const _data = await fetch(url).then((response) =>
                    response.json()
                )

                if (!isMounted) return

                setData(_data)
                setHasFailed(false)
            } catch (err) {
                // let's not do anything with the error.
                // hopefully GCP is able to log and monitor
                // errors.
                setHasFailed(true)
            }
            setIsLoading(false)
        }
        fetchUrl()

        return () => {
            isMounted = false
        }
    }, [url]) // I think this will only run once (?)

    return {
        hasFailed,
        isLoading,
        data,
    }
}
