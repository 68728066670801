import Axios from 'axios';
import { useMutation, useQueryClient } from 'react-query';
import { useAlert } from 'react-alert';
import { GET_CALCULATIONS_KEY } from '../Calculations/hooks';

export const useCreation = (creationUrl, calculationData) => {
    const queryClient = useQueryClient();
    const cb = () => Axios.post(creationUrl, calculationData);
    const alert = useAlert();
    const { mutate, data } = useMutation(cb, {
        onSuccess: async (data) => {
            alert.success('Fragrance saved!');
            await queryClient.refetchQueries(GET_CALCULATIONS_KEY);
        },
        onError: () => {
            alert.error('There was an error. Please try again.');
        },
    });

    return {
        mutate,
        data,
    };
};
