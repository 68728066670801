import React from 'react'
import { Table } from 'reactstrap'

import Headers from './Headers'
import TableBody from './TableBody'

const CSTable = ({ data }) => {
    return (
        <Table>
            <Headers />
            <TableBody data={data} />
        </Table>
    )
}

export default CSTable
