import React from 'react'
import Select from 'react-select'
import { Label } from 'reactstrap'

const OPTIONS = [
    {
        label: 'One fragrance',
        value: 1,
    },
    {
        label: 'Three fragrances',
        value: 3,
    },
]

const NumNotesInput = (props) => {
    const [localNum, setLocalNum] = React.useState()

    return (
        <>
            <Label for="num-notes">
                <h5>Number of fragrances:</h5>
                <small>
                    <i>
                        The number of fragrances you plan to use. If you tend to
                        mix fragrances, set this value to <b>three fragrances</b> (high/mid/base).
                        If you tend to stick with 1
                        fragrance, then set this to <b>one fragrance</b>.
                    </i>
                </small>
            </Label>
            <Select
                defaultValue={OPTIONS[0]}
                onChange={(event) => {
                    // this will help us manage state elsewhere
                    props.setFn(event.value)

                    // this will help us manage state for the local component.
                    // the local-component requires the schema of { value: XX, label: YY}
                    setLocalNum(event)
                }}
                value={localNum}
                options={OPTIONS}
            />
            <br />
        </>
    )
}

export default NumNotesInput
