import React from 'react';
import styled from 'styled-components';
import pluralize from 'pluralize';

const StyledErrorText = styled.span`
    color: red;
`;

export const ErrorText = ({ text }: { text: string }) => (
    <StyledErrorText>
        <small>{text}</small>
    </StyledErrorText>
);

export const pluralizeCandle = (value?: number | string) =>
    pluralize('candle', Number(value || 0));
