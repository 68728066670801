import React from 'react';
import { Card, CardBody, CardSubtitle, CardTitle } from 'reactstrap';
import { precise } from '../helpers';

type PropTypes = {
    fragranceAmount: number;
    fragranceName: string;
};

const FragranceNotesCard = (props: PropTypes) => {
    const fragranceTitle = props.fragranceName || 'Fragrance note';
    return (
        <Card style={{ backgroundColor: '#E9DAC1' }}>
            <CardBody>
                <CardTitle tag="h3">
                    {fragranceTitle}: {precise(props.fragranceAmount)} g
                </CardTitle>
                <CardSubtitle tag="h6">Fragrance note</CardSubtitle>
                <br />
                <CardTitle>
                    <p>
                        Add <b>{precise(props.fragranceAmount)} g</b> of{' '}
                        {props.fragranceName || ''} fragrance oil.
                    </p>
                </CardTitle>
            </CardBody>
        </Card>
    );
};
export default FragranceNotesCard;
