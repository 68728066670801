export enum TabNames {
    Calculator,
    Calculations,
}

export type CalculationPostData = {
    // This data is specific for the backend
    auth0_user_id?: string;
    name?: string; // TODO: check if it's okay for this

    fragrance_load_percentage: number;
    number_of_candles: number;
    vessel_size: number;

    // high_note_name: props.highNoteTitle;
    // mid_note_name: props.midNoteTitle;
    // base_note_name: props.baseNoteTitle;
    single_note_name?: string;
    num_notes: number;
    // calculation_id, // TODO: add to the front-end
    // OR actually -- this'll always be empty.
};

export type InputValuesType = {
    // these types should match the validationSchema for our input form.
    // TODO: see if we can swap this out with something that interprets that schema
    numCandles: number;
    fragranceLoadPercentage: number;
    vesselSize: number;
    candleName: string;
    fragranceName: string;
    waxDensity: number;
};
