import React from 'react'

const AdditionalExample = React.memo(() => (
    <>
        <p>
            Treat information here as the <i>advanced</i> example. I go over
            flows that aren't core to helping you get candles out the door, but
            help better organize your candlemaking experience.
        </p>
        <p>
            <i>
                The example listed here is going to be a continuation of the
                example in core usage.
            </i>
        </p>

        <p>-----------------</p>

        <h6>Attach names to your fragrance notes</h6>
        <p>
            After getting an output for our inputs, we are now ready to make our
            candles! We look back at our table and reference the high note row.
            We see that we need 44.50 grams of fragrance.
        </p>
        <p>
            <img src="/scent-calculator/first_row.png" />
        </p>
        <p>
            I don't know about you, but I have very bad short-term memory loss,
            and I have no idea what fragrance this maps to. Is this 44.50 grams
            of Tobacco? Or is it 44.50 grams of Vanilla?
        </p>
        <p>
            Don't fret. We can attach the names of our fragrances to each note
            by filling out the <b>Fragrance names</b> section. Let's say the
            fragrance we want to use for the high note is mahogany teakwood.
            We'll fill the high note section with "mahogany teakwood" and the
            ratio table rows will now reflect it.
        </p>
        <img src="/scent-calculator/filled_out.png" />
        <p>
            no more going back-and-forth in trying to remember what each
            high/mid/base note referred to. The ratio table will automatically
            reflect it.
        </p>
        <p>-----------------</p>

        <h6>Save your calculations for future use</h6>
        <p>After an hour of candlemaking, we've finished making all 10 candles! Hooray!</p>
        <p>
            Wait - how do I save my calculations so I don't have to re-input it
            into the calculator every time?
        </p>
        <p>
            Click the pink "click to generate link" button, and you'll see a
            link be produced. Save this link. next time you want to make a
            similar batch, open the generated url and all your calculations will
            be pre-populated.
        </p>
        <img src="/scent-calculator/generate_link.png" />
    </>
))

export default AdditionalExample
