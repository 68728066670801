/*
Formula:

You want 10% FO in a 20cl jar that holds 180g wax

Container Capacity = Wax + FO
CC = Wax + 0.1 * Wax
CC / (1.1) = Wax

FO = Wax * 0.1

EXAMPLE:
8oz with 10% load
CC = 236g

236 / 1.1 = Wax
Wax = 214
FO = 23.6

** To convert fluid ounce to gram, multiply the volume by 29.57353 times the density of the material

 */

import { DENSITY_SOYWAX, FL_OZ_TO_G } from '../../../constants';

type CalculationTypes = {
    numCandles: number;
    fragranceLoadPercentage: number;
    vesselSize: number;
    waxDensity?: number;
};

export const getCalculations = ({
    numCandles,
    fragranceLoadPercentage,
    vesselSize,
    waxDensity,
}: CalculationTypes) => {
    const density = waxDensity || DENSITY_SOYWAX;
    const containerAmountInGrams = vesselSize * FL_OZ_TO_G * density;
    const fragranceLoadAsDecimal = fragranceLoadPercentage / 100;

    const waxAmount = containerAmountInGrams / (1 + fragranceLoadAsDecimal);
    const fragranceAmount = waxAmount * fragranceLoadAsDecimal;

    return {
        waxAmount: waxAmount * numCandles,
        fragranceAmount: fragranceAmount * numCandles,
    };
};
