import React from 'react'
import styled from 'styled-components'

const StyledErrorText = styled.span`
    color: red;
`

export const ErrorText = (props) => (
    <StyledErrorText>
        <small>{props.text}</small>
    </StyledErrorText>
)
