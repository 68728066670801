import React from 'react'
import Inputs from '../inputs'

const FragranceInputTab = () => {
    return (
        <>
            <br />
            <Inputs />
        </>
    )
}

export default FragranceInputTab
