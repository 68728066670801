import React from 'react';
import pluralize from 'pluralize';
import { Label, Input, InputGroup, InputGroupText } from 'reactstrap';
import { ErrorText } from './common';

const NumCandlesInput = (props) => {
    const [touched, setTouched] = React.useState(false);
    return (
        <>
            <Label for="num-candles">
                <h5>Number of candles:</h5>
                <small>
                    <i>
                        Quantity of candles you expect to make. Usually this
                        will be 1; unless you are making a large batch of one
                        fragrance composition (i.e. "I have an order of 10
                        strawberry candles").
                    </i>
                </small>
            </Label>
            <InputGroup>
                <Input
                    type="number"
                    name="select"
                    id="num-candles"
                    bsSize="small"
                    value={props.value}
                    onChange={(e) => {
                        if (!touched) setTouched(true);
                        props.setFn(e.target.value);
                    }}
                    placeholder="Enter number of candles"
                />
                <InputGroupText>
                    {pluralize('candle', Number(props.value || 0))}
                </InputGroupText>
            </InputGroup>
            <div>
                {touched && !props.value ? (
                    <ErrorText text="Missing number of candles" />
                ) : null}
            </div>
            <br />
        </>
    );
};

export default NumCandlesInput;
