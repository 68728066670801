import React, { Dispatch, SetStateAction } from 'react';
import { Button, Card, CardBody, Collapse, Tooltip } from 'reactstrap';
import { FRAGRANCE_CALCULATOR_VERSIONS } from '../constants';
import { buildPropertyString } from '../../../services/amplitudeTS';

type PropTypes = {
    setVersion: Dispatch<SetStateAction<FRAGRANCE_CALCULATOR_VERSIONS>>;
    version: FRAGRANCE_CALCULATOR_VERSIONS;
};

const VersionSelector = (props: PropTypes) => {
    const [isToggleOpen, setIsToggleOpen] = React.useState(false);
    const [isTooltipOpen, setIsTooltipOpen] = React.useState(false);

    const toggleButton = () => setIsToggleOpen(!isToggleOpen);

    return (
        <>
            <Button
                color="light"
                onClick={toggleButton}
                style={{
                    marginBottom: '1rem',
                }}
                size="sm"
                id="calculator-versions-btn"
            >
                Calculator Versions
            </Button>
            <Tooltip
                target="calculator-versions-btn"
                isOpen={isTooltipOpen}
                toggle={() => setIsTooltipOpen(!isTooltipOpen)}
                placement="top"
            >
                <small>
                    Click here if you are looking for older versions of the
                    calculator
                </small>
            </Tooltip>
            <Collapse isOpen={isToggleOpen}>
                <Card>
                    <CardBody>
                        <div style={{ marginBottom: '0.5rem' }}>
                            <i>
                                <small style={{ color: 'red' }}>
                                    Versions 1 and 2 are deprecated.
                                </small>{' '}
                                <small>
                                    Version 3 contains the most accurate
                                    results.
                                </small>
                            </i>
                        </div>
                        <Button
                            onClick={() => {
                                props.setVersion(
                                    FRAGRANCE_CALCULATOR_VERSIONS.V1
                                );
                                buildPropertyString({
                                    category: 'Candles',
                                    property: 'Version',
                                    action: 'V1',
                                });
                            }}
                            size="sm"
                            color="light"
                            style={{
                                marginRight: '1rem',
                            }}
                        >
                            Version 1
                        </Button>
                        <Button
                            onClick={() => {
                                props.setVersion(
                                    FRAGRANCE_CALCULATOR_VERSIONS.V2
                                );
                                buildPropertyString({
                                    category: 'Candles',
                                    property: 'Version',
                                    action: 'V2',
                                });
                            }}
                            size="sm"
                            color="light"
                            style={{
                                marginRight: '1rem',
                            }}
                        >
                            Version 2
                        </Button>
                        <Button
                            onClick={
                                () =>
                                    props.setVersion(
                                        FRAGRANCE_CALCULATOR_VERSIONS.V3
                                    )
                                // Don't track V3 clicks since this is the
                                // default value
                            }
                            size="sm"
                            color="primary"
                            style={{
                                marginRight: '1rem',
                            }}
                        >
                            Version 3
                        </Button>
                    </CardBody>
                </Card>
            </Collapse>
        </>
    );
};

export default VersionSelector;
