import React from 'react';
import { Button, Col, Row } from 'reactstrap';
import { useCreation } from './hooks';
import Confetti from 'react-confetti';
import { useWindowSize } from 'react-use';

type PropTypes = {
    isAuthenticated: boolean;
    userId?: string;
    numCandles: number;
    fragranceLoadPercentage: number;
    vesselSize: number;
    fragranceName: string;
    candleName: string;
    postSaveCallback?: () => void;
};

const SaveCalculationBox = (props: PropTypes) => {
    const { mutate } = useCreation();
    return (
        <div style={{ backgroundColor: '#54BAB9', padding: '3rem' }}>
            <Row>
                <Col md="10">
                    <h5 style={{ marginBottom: '1.5rem' }}>
                        Want to not have to re-enter this information in the
                        future?
                    </h5>
                    <div>
                        Consider saving this calculation. By saving this
                        calculation, you'll be able to consolidate all your
                        candles fragrance calculations into one place, and no
                        more will you have to re-enter your candles'
                        information. Save your calculations and we'll pre-load
                        them everytime you login! 🎉 🎉
                    </div>
                    <br />
                    {
                        // TODO:
                        // 1. non-logged in user comes here and wants to save info / sign-up
                        // 2. maybe put this info in the front-end... and then pull from it
                        // 3. after the redirect or something...
                    }
                    {/*{!props.isAuthenticated && <LoginButton />}*/}
                    <br />
                    <Button
                        color="#eee6f57"
                        style={{
                            backgroundColor: '#ee6f57',
                            marginTop: '2rem',
                            paddingLeft: '2rem',
                            paddingRight: '2rem',
                        }}
                        size={'sm'}
                        disabled={!props.isAuthenticated || !props.userId}
                        onClick={async () => {
                            const data = {
                                // This data is specific for the backend
                                auth0_user_id: props.userId,
                                name: props.candleName,
                                fragrance_load_percentage:
                                    props.fragranceLoadPercentage,
                                number_of_candles: props.numCandles,
                                vessel_size: props.vesselSize,

                                // high_note_name: props.highNoteTitle,
                                // mid_note_name: props.midNoteTitle,
                                // base_note_name: props.baseNoteTitle,
                                single_note_name: props.fragranceName,
                                num_notes: 1, // NOTE: always 1 for now

                                // calculation_id, // TODO: add to the front-end
                                // OR actually -- this'll always be empty.
                            };
                            await mutate(data);

                            if (props.postSaveCallback) {
                                props.postSaveCallback();
                            }
                        }}
                    >
                        Save calculation
                    </Button>
                </Col>
            </Row>
        </div>
    );
};

export default SaveCalculationBox;
