import React from 'react';
import { Input, InputGroup, InputGroupText, Label } from 'reactstrap';
import { Field, FieldProps, useField } from 'formik';
import {
    ErrorText,
    pluralizeCandle,
} from '../../../../FragranceCalculatorV2/inputs/commonTS';

const VesselSizeInput = (fieldInputProps: FieldProps) => {
    return (
        <Input
            {...fieldInputProps.field}
            {...fieldInputProps.meta}
            type="number"
            bsSize="sm"
            placeholder="Enter vessel size (in oz)"
        />
    );
};

const VesselSize = () => {
    const [field, meta] = useField('vesselSize');

    return (
        <>
            <Label for="vesselSize">
                <h5>Vessel size:</h5>
                <small>
                    <i>
                        Unit of candle in oz. You'd enter <b>8</b> if you were
                        making 8oz candles.
                    </i>
                </small>
            </Label>
            <InputGroup>
                <Field name="vesselSize" component={VesselSizeInput} />
                <InputGroupText>oz</InputGroupText>
            </InputGroup>
            <div>{meta.error ? <ErrorText text={meta.error} /> : null}</div>
            <br />
        </>
    );
};

export default VesselSize;
