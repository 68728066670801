import React from 'react';

type PropTypes = {
    children: React.ReactNode;
};

const JumbotronTS = (props: PropTypes) => (
    <div className="rounded px-3 px-sm-4 py-3 py-sm-5">{props.children}</div>
);

export default JumbotronTS;
