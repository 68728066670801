import React from 'react'

const CaretDown = () => <i className="fa fa-caret-down" />
const CaretUp = () => <i className="fa fa-caret-up" />

const Caret = ({ columnDirection }) => {
    if (columnDirection > 0) {
        return <CaretUp />
    } else if (columnDirection < 0) {
        return <CaretDown />
    } else {
        return null
    }
}

const EtsyTableHeader = (props) => {
    const onCurrentColumn = props.column === props.currentColumn
    return (
        <th
            onClick={(e) => {
                if (onCurrentColumn) {
                    const columnDirection =
                        props.columnDirection === -1 ? 1 : -1
                    props.setColumnDirection(columnDirection)
                } else {
                    props.setCurrentColumn(props.column)
                    props.setColumnDirection(1) // default first click to be sorting up
                }
            }}
        >
            {props.header}
            {onCurrentColumn && (
                <>
                    &nbsp;
                    <Caret columnDirection={props.columnDirection} />
                </>
            )}
        </th>
    )
}

export default EtsyTableHeader
