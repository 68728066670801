import React from 'react';
import { Table, Tooltip } from 'reactstrap';
import styled from 'styled-components';
import { format } from 'date-fns';
import CalculationsDropdown from './CalculationsDropdown';

const DATE_FN_FORMAT = 'MM/dd/yyyy';

const CalculationsTH = styled.span`
    font-size: 2rem
    font-family: Athelas Regular
`;

const TableHeader = (props) => {
    const [isToolTipOpen, setIsToolTipOpen] = React.useState(false);
    return (
        <th>
            <Tooltip
                placement="top"
                isOpen={isToolTipOpen}
                target={props.target}
                toggle={() => setIsToolTipOpen(!isToolTipOpen)}
            >
                <span>{props.tooltipText}</span>
            </Tooltip>
            <div id={props.target}>
                <CalculationsTH>{props.text}</CalculationsTH>
            </div>
        </th>
    );
};

const TABLE_HEADER_CONFIG = [
    {
        target: 'created_on',
        text: 'Created on',
        tooltipText: 'The date the fragrance was added.',
    },
    {
        target: 'name',
        text: 'Name',
        tooltipText: 'Name of the candle',
    },
    {
        target: 'fragrance_load',
        text: 'Fragrance load %',
        tooltipText: 'Percentage of fragrance oil',
    },
    {
        target: 'num_of_candles',
        text: '# of Candles',
        tooltipText:
            'The number of candles you intend to make for the calculation',
    },
    {
        target: 'vessel_size',
        text: 'Vessel size',
        tooltipText: 'Size of the vessel to house your candles',
    },
];

const CalculationsTable = (props) => {
    const { calculations, user_id } = props;

    return (
        <Table>
            <thead>
                <tr>
                    {TABLE_HEADER_CONFIG.map((config) => (
                        <TableHeader
                            key={`${config.target}-${config.text}`}
                            target={config.target}
                            text={config.text}
                            tooltipText={config.tooltipText}
                        />
                    ))}
                    <th></th>
                </tr>
            </thead>
            <tbody>
                {calculations.map((calc, idx) => {
                    return (
                        <tr key={`idx-${calc.createdAt}`}>
                            <td>
                                {format(
                                    new Date(calc.createdAt),
                                    DATE_FN_FORMAT
                                )}
                            </td>
                            <td>{calc.name}</td>
                            <td>{calc.fragrance_load_percentage} %</td>
                            <td>{calc.number_of_candles}</td>
                            <td>{calc.vessel_size}</td>
                            <td>
                                {calc.user_id && calc._id ? (
                                    <CalculationsDropdown
                                        user_id={calc.user_id}
                                        fragrance_id={calc._id}
                                    />
                                ) : null}
                            </td>
                        </tr>
                    );
                })}
            </tbody>
        </Table>
    );
};

export default CalculationsTable;

/*

base_note_name: null
createdAt: "2020-11-25T05:27:33.821Z"
fragrance_load_percentage: 3
high_note_name: null
mid_note_name: null
name: "test"
number_of_candles: 3
single_note_name: "strawberry"
updatedAt: "2020-11-25T05:35:25.434Z"
user_id: "5fadf38b432cfd96861a4e43"
vessel_size: 3
__v: 0
_id: null
 */
