import React from 'react'
import { buildPropertyString } from '../../services/amplitude'
import { getReadableToDate } from './helpers'

export const trackGraphClick = (toDate) => {
    const readableToDate = getReadableToDate(toDate)
    buildPropertyString({
        category: 'Candles',
        property: 'Etsy search analytics tool',
        action: 'Point click for table load',
        properties: { toDate: readableToDate },
    })
}

export const useEtsyGraphMonitoring = (toDate) => {
    React.useEffect(() => {
        if (!toDate) return
        trackGraphClick(toDate)
    }, [toDate])
}
