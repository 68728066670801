import React, { ReactNode } from 'react';
import { Alert } from 'reactstrap';

type PropTypes = {
    color?: string;
    show_header?: boolean;
    as_of?: string;
    children: ReactNode;
};

const NewFeatureBannerTS = (props: PropTypes) => {
    const [showBanner, setShowBanner] = React.useState(true);
    return (
        <Alert
            color={props.color || 'info'}
            isOpen={showBanner}
            toggle={() => setShowBanner(false)}
        >
            {props.show_header && (
                <>
                    <h5>New Features:</h5>
                    <i>
                        <small>as of {props.as_of}</small>
                    </i>
                </>
            )}
            <div>{props.children}</div>
        </Alert>
    );
};

export default NewFeatureBannerTS;
