import React from 'react';
import Select from 'react-select';
import {Label} from 'reactstrap';
import {UNIT_DROPS, UNIT_GRAM, UNIT_ML, UNIT_OZ} from '../constants';

const OPTIONS = [
    {
        label: `grams (${UNIT_GRAM})`,
        value: UNIT_GRAM,
    },
    {
        label: `milliliters (${UNIT_ML})`,
        value: UNIT_ML,
    },
    {
        label: `ounces (${UNIT_OZ})`,
        value: UNIT_OZ,
    },
    {
        label: UNIT_DROPS,
        value: UNIT_DROPS,
    },
];

const UnitSelector = (props) => {
    return (
        <>
            <Label for="unit-selector">
                <h5>Unit of measurement:</h5>
                <small>
                    <i>
                        The unit you want the below calculations to be in.
                    </i>
                </small>
            </Label>
            <Select
                defaultValue={OPTIONS[0]}
                onChange={(event) => {
                    props.setUnit(event.value);
                }}
                value={{label: props.unit, value: props.unit}}
                options={OPTIONS}
                name="unit-selector"
            />
        </>
    );
};

export default UnitSelector;
