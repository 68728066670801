import React from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import FragranceLoadPercentage from './FieldInputs/FragranceLoadPercentage';
import NumberCandles from './FieldInputs/NumberCandles';
import VesselSize from './FieldInputs/VesselSize';
import CandleName from './FieldInputs/CandleName';
import FragranceName from './FieldInputs/FragranceName';
import Results from './Results';
import WaxDensity from './FieldInputs/WaxDensity';

const CalculatorSchema = Yup.object().shape({
    fragranceLoadPercentage: Yup.number()
        .required('Fragrance load percentage is required')
        .min(1, 'Fragrance load percentage must be greater than or equal to 1%')
        .max(
            100,
            'Fragrance load percentage must be less than or equal to 100%'
        ),
    numCandles: Yup.number()
        .required('Number of candles is required')
        .min(1, 'Number of candles must be greater than or equal to 1 candle')
        .integer('Number of candles must be an integer'),
    vesselSize: Yup.number()
        .required('Vessel size is required')
        .min(1, 'Vessel size must be greater than or equal to 1oz'),
    waxDensity: Yup.number().default(0.9),
    candleName: Yup.string(),
    fragranceName: Yup.string(),
});

const Calculator = () => {
    return (
        <Formik
            initialValues={{
                fragranceLoadPercentage: '',
                numCandles: '',
                vesselSize: '',
                candleName: '',
                fragranceName: '',
                waxDensity: 0.9,
            }}
            onSubmit={(values) => {
                console.log({ values });
            }}
            validationSchema={CalculatorSchema}
        >
            <Form>
                <FragranceLoadPercentage />
                <br />
                <NumberCandles />
                <br />
                <VesselSize />
                <br />
                <WaxDensity />
                <br />
                <CandleName />
                <br />
                <FragranceName />
                <br />
                <Results />
            </Form>
        </Formik>
    );
};

export default Calculator;
