import React from 'react';
import CalculationsTable from './CalculationsTable';
import { useCalculations } from './hooks';
import { URLS } from '../../constants';
import Loading from '../../../../../components/Loading';

type PropTypes = {
    userId: string;
};

const Calculations = (props: PropTypes) => {
    const calculationsUrl = `${URLS.fragranceCalculationUrl}/${props.userId}`;
    const { isLoading, error, data } = useCalculations(calculationsUrl);
    if (isLoading) {
        return <Loading />;
    } else if (!data.length) {
        return (
            <div className="center">
                <iframe
                    src="https://giphy.com/embed/1BFVM0k9zxttqOpUwc"
                    width="480"
                    height="480"
                    frameBorder="0"
                    className="giphy-embed"
                    allowFullScreen
                ></iframe>
                <h6>No calculations found. Time to create some.</h6>
            </div>
        );
    }
    return <CalculationsTable calculations={data} userId={props.userId} />;
};

export default Calculations;
