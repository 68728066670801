import React from 'react'
import styled from 'styled-components'

const ExampleBtn = styled.div`
    text-align: center;
    padding: 0.5rem;
    color: ${(props) => props.color};
    background-color: ${(props) => {
        return props.bgColor
    }};
    cursor: pointer;
    border-radius: 0.25rem; // pulled from bootstrap
`

export default ExampleBtn
