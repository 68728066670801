import React from 'react'
import { MdClear } from 'react-icons/md'

const Cancel = (props) => (
    <span style={{ paddingLeft: '1rem' }} className="color-cursor-hover">
        <MdClear onClick={props.cb} />
    </span>
)

export default Cancel
