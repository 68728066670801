import React from 'react'
import {
    LAST_SEARCHED_KEY,
    validateLastSearch,
    wrappedSetLastSearched,
} from './utils'

export const LastSearchedContext = React.createContext({})
export const LastSearchedDispatch = React.createContext(() => {})

const LastSearchedProvider = ({ children }) => {
    let initial = ''
    if (typeof window !== `undefined`) {
        initial = window.localStorage.getItem(LAST_SEARCHED_KEY)
    }
    const [lastSearched, setLastSearched] = React.useState(initial)

    // The `lastSearched` should be set _every time_ the user
    // either hits a valid state -- or they _explicitly_ hit
    // the generated url state.

    React.useEffect(() => {
        try {
            if (typeof window !== `undefined`) {
                const storage = window.localStorage

                if (!storage) return

                const lastSearchUrl = window.localStorage.getItem(
                    LAST_SEARCHED_KEY
                )

                const hasValidLastSearch = validateLastSearch(lastSearchUrl)

                if (hasValidLastSearch) {
                    wrappedSetLastSearched(lastSearchUrl, setLastSearched)
                }
            }
        } catch (err) {}
    }, [setLastSearched])

    return (
        <LastSearchedContext.Provider value={lastSearched}>
            <LastSearchedDispatch.Provider value={wrappedSetLastSearched}>
                {children}
            </LastSearchedDispatch.Provider>
        </LastSearchedContext.Provider>
    )
}

export default LastSearchedProvider
