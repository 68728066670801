import React from 'react'
import NoteRow from './NoteRow'
import TotalRow from './TotalRow'

const MultiFragrance = (props) => {
    return (
        <>
            <NoteRow
                quantity={props.quantity}
                waxToFragranceRatio={props.waxToFragranceRatio}
                note={'HIGH'}
                title={props.highNoteTitle}
            />
            <NoteRow
                quantity={props.quantity}
                waxToFragranceRatio={props.waxToFragranceRatio}
                note={'MID'}
                title={props.midNoteTitle}
            />
            <NoteRow
                quantity={props.quantity}
                waxToFragranceRatio={props.waxToFragranceRatio}
                note={'BASE'}
                title={props.baseNoteTitle}
            />
            <TotalRow
                quantity={props.quantity}
                waxToFragranceRatio={props.waxToFragranceRatio}
            />
        </>
    )
}

export default MultiFragrance
