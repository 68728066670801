import React from 'react';
import { Table, Tooltip } from 'reactstrap';
import styled from 'styled-components';
import { format } from 'date-fns';
import CalculationsDropdown from './CalculationsDropdown';
import { CalculationType } from './types';

const DATE_FN_FORMAT = 'MM/dd/yyyy';

const CalculationsTH = styled.span`
    font-size: 2rem
    font-family: Athelas Regular
`;

type TableHeaderPropTypes = {
    target: string;
    tooltipText: string;
    text: string;
};

const TableHeader = (props: TableHeaderPropTypes) => {
    const [isToolTipOpen, setIsToolTipOpen] = React.useState(false);
    return (
        <th>
            <Tooltip
                placement="top"
                isOpen={isToolTipOpen}
                target={props.target}
                toggle={() => setIsToolTipOpen(!isToolTipOpen)}
            >
                <span>{props.tooltipText}</span>
            </Tooltip>
            <div id={props.target}>
                <CalculationsTH>{props.text}</CalculationsTH>
            </div>
        </th>
    );
};

const TABLE_HEADER_CONFIG = [
    {
        target: 'created_on',
        text: 'Created on',
        tooltipText: 'The date the fragrance was added.',
    },
    {
        target: 'name',
        text: 'Name',
        tooltipText: 'Name of the candle',
    },
    {
        target: 'fragrance_load',
        text: 'Fragrance load %',
        tooltipText: 'Percentage of fragrance oil',
    },
    {
        target: 'num_of_candles',
        text: '# of Candles',
        tooltipText:
            'The number of candles you intend to make for the calculation',
    },
    {
        target: 'vessel_size',
        text: 'Vessel size',
        tooltipText: 'Size of the vessel to house your candles',
    },
];

type PropTypes = {
    userId: string;
    calculations: Array<CalculationType>;
};

const CalculationsTable = (props: PropTypes) => {
    return (
        <Table>
            <thead>
                <tr>
                    {TABLE_HEADER_CONFIG.map((config) => (
                        <TableHeader
                            key={`${config.target}-${config.text}`}
                            target={config.target}
                            text={config.text}
                            tooltipText={config.tooltipText}
                        />
                    ))}
                    <th></th>
                </tr>
            </thead>
            <tbody>
                {props.calculations.map((calc, idx) => {
                    return (
                        <tr key={`idx-${calc.createdAt}`}>
                            <td>
                                {format(
                                    new Date(calc.createdAt),
                                    DATE_FN_FORMAT
                                )}
                            </td>
                            <td>{calc.name}</td>
                            <td>{calc.fragranceLoadPercentage} %</td>
                            <td>{calc.numberOfCandles}</td>
                            <td>{calc.vesselSize}</td>
                            <td>
                                {calc.userId && calc.id ? (
                                    <CalculationsDropdown
                                        userId={props.userId}
                                        fragranceId={calc.id}
                                    />
                                ) : null}
                            </td>
                        </tr>
                    );
                })}
            </tbody>
        </Table>
    );
};

export default CalculationsTable;
