export const WOODEN_WICK_KEY = 'wooden_wick';
export const WOODEN_WICK_LABEL = 'The Wooden Wick Co.';

export const LONESTAR_KEY = 'lonestar';
export const LONESTAR_LABEL = 'Lone Star Candle Supply';

export const CANDLESCIENCE_KEY = 'candlescience';
export const CANDLESCIENCE_LABEL = 'Candlescience';

export const AZTEC_KEY = 'aztec';
export const AZTEC_LABEL = 'Aztec Candle & Soap Supplies';

export const VENDOR_TYPE_MAP = {
    [WOODEN_WICK_KEY]: WOODEN_WICK_LABEL,
    [LONESTAR_KEY]: LONESTAR_LABEL,
    [CANDLESCIENCE_KEY]: CANDLESCIENCE_LABEL,
};

export const VENDOR_OPTIONS = [
    {
        value: WOODEN_WICK_KEY,
        label: WOODEN_WICK_LABEL,
    },
    {
        value: LONESTAR_KEY,
        label: LONESTAR_LABEL,
    },
    {
        value: CANDLESCIENCE_KEY,
        label: CANDLESCIENCE_LABEL,
    },
    {
        value: AZTEC_KEY,
        label: AZTEC_LABEL,
    },
];
