import React from 'react';
import { Table, Tooltip } from 'reactstrap';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { BiLinkAlt, BiInfoCircle } from 'react-icons/bi';
import { trackPriceLinkClick } from './monitoring';
import PricingModal from './PricingModal';
import { VENDOR_TYPE_MAP } from '../constants';
import { formatFragranceName } from '../helpers';

const WrapWithInfoIcon = (props) => {
    const [tooltipOpen, setTooltipOpen] = React.useState(false);
    const tooltipId = `${props.text}-tooltip-id`;
    const toggle = () => setTooltipOpen(!tooltipOpen);
    return (
        <>
            <th>
                {props.text}
                <Tooltip
                    isOpen={tooltipOpen}
                    target={tooltipId}
                    toggle={toggle}
                    placement="top"
                >
                    {props.tooltipText}
                </Tooltip>{' '}
                <BiInfoCircle id={tooltipId} />
            </th>
        </>
    );
};

const PricingTable = (props) => {
    const [isModalOpen, setIsModalOpen] = React.useState(false);
    const [details, setDetails] = React.useState();
    const toggle = () => setIsModalOpen(!isModalOpen);

    return (
        <>
            <PricingModal toggle={toggle} isOpen={isModalOpen} {...details} />
            <Table responsive hover>
                <thead>
                    <tr>
                        <th></th>
                        <th>Name</th>
                        <WrapWithInfoIcon
                            text="Price"
                            tooltipText="Price range for fragrance."
                        />
                        <th>Vendor</th>
                        <WrapWithInfoIcon
                            text="Date"
                            tooltipText="The last date we recorded a change. If the date is in the past, that means no price/inventory changes have been seen since."
                        />
                        <th>Link</th>
                    </tr>
                </thead>
                <tbody>
                    {props.data.map((el) => {
                        return (
                            <tr
                                key={`${el.name}-${el.vendor}`}
                                onClick={() => {
                                    // TOOD: add additional props
                                    setIsModalOpen(true);
                                    setDetails({
                                        name: el.name,
                                        vendor: el.vendor,
                                    });
                                }}
                                className="cursor-hover"
                            >
                                <td>
                                    {el.image_url && (
                                        <LazyLoadImage src={el.image_url} />
                                    )}
                                </td>
                                <td>{formatFragranceName(el.name)}</td>
                                <td>
                                    ${el.min_price} to ${el.max_price || '+'}
                                </td>
                                <td>{VENDOR_TYPE_MAP[el.vendor]}</td>
                                <td>{el.date}</td>
                                <td>
                                    {el.url && (
                                        <BiLinkAlt
                                            href={el.url}
                                            className="cursor-hover"
                                            onClick={() => {
                                                trackPriceLinkClick(
                                                    el.name,
                                                    el.url,
                                                    el.vendor
                                                );
                                                window.open(el.url, '_blank');
                                            }}
                                        />
                                    )}
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </Table>
        </>
    );
};

export default PricingTable;
