import React from 'react';
import Select from 'react-select';

import makeAnimated from 'react-select/animated';

import { VENDOR_OPTIONS } from '../constants';

const animatedComponents = makeAnimated();

const VendorSelect = (props) => {
    return (
        <Select
            isMulti
            options={VENDOR_OPTIONS}
            components={animatedComponents}
            value={props.vendors}
            onChange={(e) => {
                props.setVendors(e);
            }}
        />
    );
};

export default VendorSelect;
